/* You can add global styles to this file, and also import other style files */
/* ===================================================
                    Fonts
=================================================== */
/* hebrew */
@font-face {
  font-family: 'Heebo';
  font-style: normal;
  font-weight: 500;
  src: url(https://fonts.gstatic.com/s/heebo/v21/NGSpv5_NC0k9P_v6ZUCbLRAHxK1EiSysd0mm_00.woff2) format('woff2');
  unicode-range: U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
}
/* latin */
@font-face {
  font-family: 'Heebo';
  font-style: normal;
  font-weight: 500;
  src: url(https://fonts.gstatic.com/s/heebo/v21/NGSpv5_NC0k9P_v6ZUCbLRAHxK1EiSysdUmm.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Heebo","openSansHebrew";
  /* src: url("../webfonts/openSansHebrew/OpenSansHebrewCondensed-Bold.ttf"); */
  src: url("assets/webfonts/openSansHebrew/OpenSansHebrewCondensed-Bold.ttf");
}
@font-face {
  font-family: DavidLibre;
  /* src: url("../webfonts/custom/DavidLibre-Regular.ttf"); */
  src: url("assets/webfonts/custom/DavidLibre-Regular.ttf");
}
@font-face {
  font-family: FrankRuhlLibre;
  /* src: url("../webfonts/custom/FrankRuhlLibre-Regular.ttf"); */
  src: url("assets/webfonts/custom/FrankRuhlLibre-Regular.ttf");
}
@font-face {
  font-family: MiriamLibre;
  /* src: url("../webfonts/custom/MiriamLibre-Regular.ttf"); */
  src: url("assets/webfonts/custom/MiriamLibre-Regular.ttf");
}

@import url("//fonts.googleapis.com/css?family=Open+Sans|Roboto&display=swap");
@import url("//fonts.googleapis.com/css?family=Heebo&display=swap");

/* ===================================================
                General Font rules
====================================================== */
:root{
  --green:#5fa605;
  --dark-green:#3E6C05;
  --light-green:#e4efdc;
  --light-grey:#ecefe9;
  --pink:#ff456f;
  --red:#ff2a56;
  --orange:#ffc856;

  --black:#2f2f2f;
  --dark-grey:#7a7a7a;
  --grey:#ccc;
  --bg:#fdfdfd;

  --cal-width: ((100% - 26px) / 6 + 26px + 7px);
  --followup-patient-width: min(calc(100% - var(--cal-width)),calc(100% - 300px));
}
/* html {
  -ms-touch-action: manipulation;
  touch-action: manipulation;
} */

body {
  color:#2f2f2f;
  font-size: 14px;
  font-family: "Heebo", sans-serif;
  line-height: initial;
  background-color: var(--bg);
}
button{
  outline:none;

  &.close{
    padding: 0;
    cursor: pointer;
    background: 0 0;
    border: 0;

    .modal-header &,.panel-head & {
      left:inherit;
      /*opacity: 0.5;*/
      color: var(--dark-grey);

      &:hover{
        opacity: 0.7;
      }
    }
    .panel-head &{
      margin-inline: 2px 20px;
    }

    .modal-header &{
      float:left;
      margin-inline-start: 3px;
      font-size: 17px;

      [dir=rtl] &{
        float: right;
      }
    }
  }
  &.back-to{
    display: flex;
    align-items: center;
    margin-block: 8px;
  }
  .btn-group &{
    border-radius: 4px !important;
  }
  i{
    &.fa-spin {
      color: white !important;
      margin-inline-start: 4px;
    }
  }

    &:has(app-icon+span) {
      display: flex !important;
    justify-content: center;
    align-items: center;
    gap: 8px;
    }

}
.fa-circle-xmark:hover {
  filter: brightness(0.7);
}

input{
  outline:none;

  &[type=checkbox],&[type=radio]{
    accent-color: var(--green);
  }

  &::placeholder{
    opacity: 0.7 !important;
  }
}
textarea{
  &::placeholder{
    opacity: 0.7 !important;
  }
}
.pad-btns{
  margin-top: 21px;
}
.colstat {
  margin-inline-end: 4%;
  padding-inline: 3px;

  @media (max-width: 576px) {
    margin-inline-end: 3%;
  }
}
.image-upload-button{
  border:0 !important;
  padding: 4px 10px !important;
  box-shadow: inset 0 -1px 0 #3534342b !important;
  color:white !important;
  font-size: 14px !important;

  &:hover{
    background-color: #3E6C05 !important;
    border-color: #3E6C05 !important;
  }
}
.colstat-extra-small {
  width: 54px;

  @media (max-width: 576px) {
    width: 15.5%;
  }
}
.colstat-time{
  width: 100px;
}
.colstat-price{
  width: 95px;

  @media (max-width: 576px) {
    width: 26%;
  }
}
.colstat-small {
  width: 130px;

  @media (max-width: 576px) {
    width: 30%;
  }
}
.colstat-medium {
  width: 140px;
}

.colstat-big {
  width: 237px;
}
.colstat-big-double {
  width: 310px;
}
.colstat-small-double{
  width:   calc(10% + 260px);

}
.colstat-small-triple{
  width:   calc(10% + 390px);

}
.form-control {
  margin-block: 2px;
  padding: 4px;
  color:#2f2f2f;
  height: 30px;
  font-size:14px;
  border: 0;
  box-shadow: inset 0 -1px 0 #3534342b;

  &:focus {
   /* border-color: var(--grey);*/
   /*box-shadow: none;*/
    box-shadow: inset 0 -1px 0 #3534342b;
  }
}
.search-control {
  margin-block: 2px;
  padding: 4px;
  color:#2f2f2f;
  height: 30px;
  font-size:14px;
  border: 0;
  box-shadow: inset 0 -1px 0 #3534342b;

  &:focus {
   /* border-color: var(--grey);*/
   /*box-shadow: none;*/
    box-shadow: inset 0 -1px 0 #3534342b;
  }
}

.btn{
  font-size:14px;
  margin-inline-end: 2px !important;
  margin-block: 2px;
 /* margin-bottom: 1px;*/
  padding: 4px 10px;
  border-radius: 4px;
  /* background-color: #F0F0F0; */
  line-height: inherit;
  box-shadow: inset 0 -1px 0 #3534342b;
  font-weight: 500;


  &:hover{
    color:#2f2f2f;
  }

}

.btn-check:focus+.btn, .btn:focus {
  box-shadow: none !important;
}

.btn-tab
  {
    margin-block: 0;
  }

td{
  color:#2f2f2f;
  padding: 3px;
  padding-block: 0px !important;
}

a {
  transition: 0.2s;
  text-decoration: none;
  /*color: var(--green);*/
  color : #333;

  &:hover {
    text-decoration: none;
    color: var(--dark-green);
  }
}


li {
  list-style: none
}
.announces {
  & li {
    list-style: disc;
  }
  }
ul {
  padding: 0
}

.valign-mid{
  vertical-align: middle;
}

.btn-danger{
  height: 30px;
  color: #fffff7;

  &:hover{
    color: #fffff7
  }
}

.btn-primary{
  font-size:14px;
  height: 30px;

  &:focus{
    box-shadow: none !important;
    background-color: var(--dark-green) !important;
    color: white;
  }
  i{
    color: inherit !important;
  }
}
.btn-default{
  height: 30px;
  background-color: #e2e2e1;
  /*border-color: #ccc;*/
  border: 0;
  text-shadow: none !important;

  &:hover{
    /*background-color: #e6e6e6;*/
    background-color: #c8bdba;
    /*border-color: #adadad;*/
  }
}



.btn-tall {
  font-size: 12px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.scheduler-content{
  padding-inline: 20px;

  .agree-to-terms {
    width: 15px !important;
    height: 15px !important;
    margin: 5px !important;
  }
  p{
    font-size: 14px;
    margin-inline-end: 20px;
  }
  h4 {
    @media (max-width: 991px) {
      font-size: 22px;
    }
  }
  .btn{
    font-size: 14px;
    font-weight: bold;
  }
}

/*
input,
input::placeholder {
  font-size: 12px;
}*/

h1 {
  font-size: 60px;
}

h2 {
  font-size: 42px;
}
h4 {
  font-size: 18px;
}

h5,.h5 {
  font-size: 14px;
}


.numberCircle {
  /*border-radius: 50%;*/
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  padding: 4px;
  width: 20px;
  height: 20px;

  @media (max-width: 991px) {
    padding: 2px;
  }
}


.green {
  color: var(--green) !important;
}
.grey {
  color: var(--grey) !important;
}
.flex{
  display: flex;
}
.flex-v-center{
  display: flex;
  align-items: center;
}
.flex-center{
  display: flex;
  align-items: center;
  justify-content: center;
}

.tests-autotext-menu-list{
  padding: 10px;
  border-radius: 4px;
  margin-block: 10px;
  cursor: pointer;
  background-color: var(--light-green);
  border: 0;
  box-shadow: inset 0 -1px #3534342b;
  color: var(--black);

  &:hover{
    background: var(--green);

    & > div:first-child{
      color: white;
      i{
        color:white;
      }
    }
  }

}


.comp-menu{
  margin-inline: 10px;

  button{
    display:block;
    margin-block: 10px;
  }
}
/* ===================================================
                    Modal
====================================================== */


/*.modal.fade .modal-dialog {*/
/*  transform: translateY(100%);*/
/*  transition: transform 0.3s ease-out 0s;*/
/*}*/

/*.modal.fade.show .modal-dialog {*/
/*  transform: translateY(0px);*/
/*}*/

.modal-backdrop{
  opacity: 0 !important;
}
.modal{
	overflow-y:auto;
	cursor: not-allowed;
  @media (min-width:992px) {
  overflow-y: auto;
  }

  .modal-dialog{
    cursor: auto;
    margin-top: 18px;
    max-width: inherit;
    transition: none;
    /* box-shadow: 0 12px 100px 50px #35343470; */
    box-shadow: 0 46px 200px -12px #3f3f3f;
    @media (max-width: 991px) {
      transform: translateY(100%);
      transition: transform 0.3s ease-out 0s;
      width: auto !important;
      /*max-width: calc(100vw - 20px);*/
      max-width: 100%;
      margin-top: 50px !important;
      margin: 0;
      background: var(--bg);
      min-height: calc(100svh - 115px);
    }
    &.show{
      .modal-dialog {
        @media (max-width: 991px) {
          transform: translateY(0px);

        }
      }
    }

    .modal-content {
      padding: 5px;
      border: 0;
      background: var(--bg);
      min-height: 250px;


      @media (max-width: 991px) {
        /* border-top: 1px solid #ccc !important;*/
        border-radius: 0;
        height: 100%;
        padding: 0;
        border: 0;
        padding-bottom: 100px;
      }

      .modal-body {
        padding: 5px;

        @media (min-width: 992px) {
          overflow: scroll;
          max-height: 90vh;
          padding: 5px 25px 20px;
        }
      }
      .modal-header{
        padding: 2px;
        /* display: block; */
        cursor: grab;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        &:hover {
          background-color: var(--light-green);
        }

        h3 {
          font-size: 20px;
          color: var(--green);
          margin-inline-end: 10px;
          font-weight: 700;
          text-wrap-style: balance;

          &:first-of-type {
            margin-inline-start: 10px;
          }

          @media (min-width: 992px) {
            font-size: 32px;
          }
        }

        @media (max-width: 991px) {
          border-top: 1px solid #ccc!important;
          position: sticky;
          top: 50px;
          background: var(--bg);
          z-index: 50000;
          border-radius: 0;
        }

      }
    }
  }
}



.modal-bottom-lg-pad {
  padding: 60px 0;
}

.input-group-addon {
  margin-block: 2px;
  padding: 5px;
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  color: #555;
  text-align: center;
  background-color: white;
  /*border: 1px solid #ccc;*/
  border: 0;
  box-shadow: inset 0 -1px 0 #3534342b;
  /*border-radius: 4px;*/
  display: flex;
  align-items: center;
  justify-content: center;
  border-inline-start: 0;
  border-radius: 40px 0 0 40px !important;

  @media (max-width: 991px) {
    padding: 1px;
  }
}
/*label {*/
/*  font-weight: bold;*/
/*  font-size: 12px;*/
/*}*/

label{
  font-size: 10px;
  color: var(--black);
  font-weight: 500;

  @media (max-width: 991px) {
    margin-left: 5px;
    margin-bottom: 0px;
    font-size: 10px;
  }
}

.side-scroll {
  overflow-y: auto;
  /*overflow-y: auto;*/
  overflow-x: hidden;

  @media (min-width: 992px) {
    /*height: calc(100vh - 88px - 55px);*/
    max-height: calc(100svh - 205px);
    /* screen height - site menu - patient banner */
  }
}

*[class^="col-"]{
  padding-inline: 3px;
}
th {
  padding: 3px;
  cursor: default;
}
.row {
  /*margin-inline: -3px;*/
  margin-inline: 0;
  margin-bottom: 7px;
}
.buttons-row {
  margin-block: 15px;
}

.form-group {
  padding-inline: 3px;

  .row,.row &{
    margin-bottom: 5px;
  }
  @media (max-width: 991px) {
    margin-bottom: 8px;
  }
}

.h1,.h2,.h3,h1,h2,h3 {
  margin-block: 1px;
}
.h4, .h5, .h6, h4, h5, h6 {
  margin-block: 10px;
}

.panel-head {
  /*margin-block: 5px;
  padding-inline: 5px;
  padding-top: 2px;*/
}

.panel-body {
  /*padding-inline: 20px;*/
  padding-top: 0px;
  margin-top: 0px;

  @media (max-width: 991px) {
    padding-inline: 8px;
  }
}

.table {
  margin-bottom: 20px;
  color: var(--black);

  &>tbody,&>thead{
    &>tr{
      &>td,&>th{
        border-top: 0;
      }
      &>td{
        border-bottom: 0;
      }
    }
  }
}
.table>:not(caption)>*>* {
  padding-inline-start: 0 !important;
}

table{
  &.pad-block{
    td{
      padding-block:1px !important;
    }
  }
}

tbody, td, tfoot, th, thead, tr {
  border-color: var(--grey) !important;
}

.table-hover>tbody>tr:hover {

  background: var(--light-green);

}

/* .btn:focus{
  outline: none !important;
  outline-offset: 0px !important;
} */

.link{
  cursor: pointer;
  font-weight: bold;
  color: white;
}
.link-locations{
  cursor: pointer;
  font-weight: bold;

}
.link-patient{
  cursor: pointer;
  font-weight: bold;
}



.small-events .fc .fc-event, .fc .fc-scrollgrid table tr {
  min-width: 30px !important;
  max-width: 70px !important;
}

.fc .fc-scrollgrid-section, .fc .fc-scrollgrid-section table, .fc .fc-scrollgrid-section > td {
  /* box-shadow: -8px 13px 23px -25px #35343438; */
}

.fc-direction-ltr .fc-daygrid-event.fc-event-end, .fc-direction-rtl .fc-daygrid-event.fc-event-start {
  text-wrap: wrap;
}
.fc-col-header, .fc-daygrid-body, .fc-scrollgrid-sync-table {
  width: 100% !important;
  table-layout: fixed;
}

.fc-timegrid-event-harness.fc-timegrid-event-harness-inset {
  line-height: 13px;
  box-shadow: 0 7px 11px -8px #353434a6;
}

.fc .fc-timegrid-slot-minor {
  border-top: 1px solid #d8dbdc4d !important;
}
td.fc-timegrid-slot.fc-timegrid-slot-lane {
  border: 1px solid #5fa6050f !important;
}
td.fc-timegrid-slot.fc-timegrid-slot-lane.fc-timegrid-slot-minor {
  border: 1px solid #5fa6052e !important;
}

.fc-timegrid-slots table {
  @media (min-width: 992px) {
    margin-top: 49px;
  }
}

.panel {
  margin-bottom: 20px;
  padding-bottom: 20px;
  /*background-color: #fff;*/
  /* border: 1px solid transparent; */
  /*border-radius: 4px;
 box-shadow: inset 0 -1px #3534342b;
  border-color: #ddd;*/
  border: 0;

 /* @media (min-width: 992px) {
    margin-top: 8px;
  }*/
  @media (min-width: 576px) {
    padding: 20px;
  }

  @media (max-width: 991px) {
    /*border-right: 0 0 4px 4px;*/
    margin-inline: -6px;
    border: 0;
    border-radius: 0;
    margin-bottom: 0;
    min-height: calc(100vh - 95px - 105px);
  }

}

.gen-title {
  margin: 0;
  font-size: 32px;
  font-weight: bold;
  color: var(--green);
  cursor: default;
  margin-bottom: 20px;

  @media (max-width: 991px) {
    font-size: 20px;
   /* letter-spacing: 1px;*/
  }
}
.gen-filter {
  font-size: 18px;

}
.gen-title-title {
  margin-inline-end: 10px;
}

.btn-primary,.bg-primary {
  border-radius: 4px;
  color: #fff !important;
  background-color: var(--green) !important;
  border-color: var(--green) !important;
  /* text-shadow: 3px 1px 0 #3534342b; */
}

.btn-icon {
  border: none;
  background: transparent;
  color: #999;
  min-height: 30px;
  padding: 0 4px !important;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  box-shadow: none;
}

.btn-plus{
  min-height: 16px;
  height: 16px;
}

.cal-btn,.btn-primary,.bg-primary{
  &:hover{
    background-color: var(--dark-green) !important;
    border-color: var(--dark-green) !important;
  }

}


/*.btn-primary,.bg-primary {
  &:active{
    background-color: var(--orange) !important;
    border-color: var(--orange) !important;
  }
}*/

#home-side-patient-col {
  /*padding-left: 0;*/
  margin-top: 19px;
  position: sticky;
  top: 160px;
  padding: 20px;
  height: fit-content;
  border-radius: 4px;

  @media (min-width: 992px) {
    max-width: 400px;
  }
  img {
    height: 25px !important;
    /* filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)) drop-shadow(0px 1px 0px #DAD9CC); */
    margin-block: 5px;
  }
  @media (max-width: 991px) {
    /*padding: 5px;*/
    /*margin-top: 55px;*/
    margin-top: 10px;
    margin-bottom: 25px;
  }
}
.modal #home-side-patient-col {
  top: 0;
  height: 100%;
}
/*#home-side-patient-col {*/
/*  padding-inline-end: 0;*/
/*  margin-top: 10px;*/
/*  position: fixed;*/
/*  inset-inline-end: 11px;  */
/*  height: calc(100% - 165px);*/
/*  overflow-y: auto;  !* To remove and return it to the  .side-scroll*!*/
/*}*/

.modal {
 & .gen-title-title, #period-range {
    display:none;
}
}


#person-side-col{
  img {
    height: 25px !important;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)) drop-shadow(0px 1px 0px #DAD9CC);
    margin-block: 5px;
  }
}

hr {
  margin-top: 2px;
  background-color:#eee;
  opacity: 1;
}


/* ----------- mobile changes start ----------- */

.fc-event {
  border-width: 3px !important;
}


/* Template styling */

/* Toast */
/* The snackbar - position it at the bottom and in the middle of the screen */
.snackbar {
  visibility: hidden;
  min-width: 250px;
  background-color: var(--green);
  color: #fff;
  text-align: center;
  border-radius: 4px;
  padding: 16px;
  position: fixed;
  z-index: 999999;
  left: 64px;
  bottom: 66px;
  opacity: 1;
  transition:none;

  i{
    color:#fff !important;
  }
  &.doShow {
    visibility: visible;
    animation: fadein 0.5s, fadeout 0.5s 2.5s forwards;
  }
  &.download{
    bottom: 6px;
    visibility: visible;
    display:flex;
    justify-content: space-around;
    transition: bottom 0.5s,opacity 0.5s;

    &.doHide  {
      bottom:-60px;
      opacity: 0;
      /* animation: fadeinDownload 0.5s, fadeoutDownload 0.5s 2.5s forwards; */
    }
  }
}

@keyframes fadein {
  from {bottom: -60px;opacity: 0;}
  to {}
}

@keyframes fadeout {
  from {}
  to {bottom: -60px;opacity: 0;}
}

.validation-checked {
  border-color: var(--green) !important;
}

.validation-error {
  border-color: red !important;
}

.modal-wide {
  width: 80%;
}
.modal-fit-content {
  width:fit-content;
}

.modal-full-width {
  width: 98%;
}

.modal-default-width {
  width: 60%;

  @media (max-width: 991px) {
    width: 100% !important;
  }
}
.modal-650 {
  width: 650px;
}
.modal-1000 {
  width: 1000px;
}
.modal-1200 {
  width: 1200px;
}

.modal-800 {
  width: 800px;
}
.modal-750 {
  width: 750px;

}

.modal-narrow-width {
  width: 400px;
}

.modal-small-width {
  width: 500px;
}
.modal-vsmall-width {
  width: 400px;
}

span{
  &.autocomplete-highlight {
    /*background: yellow;*/
    background: var(--orange);
    border-radius: 4px;
    /*color: white;*/
  }
}

.print-show {
  display: none;
}

.cal-btn {
  font-size: 24px;
  border-radius: 4px;
  color: #fff !important;
  background-color: var(--green);
  line-height: 20px;
  padding: 4px;
  border: 0;
  margin: 0 2px;
  /*height: 35px;*/
  height: 30px;
  box-shadow: inset 0 -1px 0 #3534342b !important;
  text-shadow: 3px 1px 0 #3534342b;

  [dir=ltr] &{
    @media (min-width: 1400px) {
      font-size: 20px;
    }
  }

  &.active{
    background-color: var(--dark-green);
  }
  i{
    vertical-align: middle;
  }
}


.cal-icon{
  i{
    color: white !important;
  }

  @media (min-width: 1400px) {
    width: 40px;
  }
  @media (max-width: 991px) {
    width: 40px;
  }
}



.fc-scrollgrid{
  border: 0 !important;

  &>thead {
    position: fixed;
    top: 116px;
    display: table;
    z-index: 900;

    .time-allocation &{
      top: 124px;
    }

    @media (max-width: 991px) {
      /* position: fixed; */
      top: 85px;

      .hidden-calendar &{
        position: absolute !important;
      }
    }
  }
}

.home-container .fc-scrollgrid > thead {
  @media (min-width: 992px) {
    width : max(300px,var(--cal-width)) ;
  }
}

.calendar-above-row {
  display: flex;
  justify-content: space-between;
  position: sticky;
  z-index: 901;
  top: 86px;
  background: var(--bg);
  align-items: flex-start;
  position: fixed;
  padding-inline-start: 6px;
  height: 30px;


  .hidden-calendar &{
    @media (max-width: 991px) {
      position: absolute !important;
    }
  }

  /*@media (min-width: 992px) {
    width: calc(100% - 40px);
    /*padding-bottom: 10px;
  } */
  @media (max-width: 991px) {
    /* top: 38px; */
    width: 100%;
    top: 50px !important;
    height: 40px;
   /* height: 30px;*/
  }

  &.cal-vers {
    /*margin-bottom:10px;
    padding-top:4px;*/
    /* top:86px; */
    /* align-items: center; */

    @media (min-width: 1400px) {
      width: calc(100% - 20px)
    }

    @media (max-width: 991px) {
      justify-content: center;
    }
  }
}



.pointer {
  cursor: pointer;
}


.fc{
  .fc-timegrid-slot {  /* TODO think how we can make this a vriable or enlarge it according to screen size */
    height: 23px !important;
    cursor: pointer;
    border: 10px solid transparent;

    @media (max-width: 991px) {
      height: 33px !important;
    }

    @media (max-height: 667px) {
      height: 30px !important;
    }

    .printScreenMode & {
      height: 22px !important;
    }
    .short-meetings-calendar &{
      height: 37px !important;
    }
  }
}



.fc-timegrid-col {
  background: white;
}

.fc-theme-standard{
  td,th {
   border-width: 2px !important;
   border-color: var(--bg) !important;
 }
}
.fc-col-header-cell.fc-day& {
  background: var(--bg) !important;
}

.fc-day-today {
  background: var(--light-grey) !important;

  .fc-col-header-cell&,.home-container &{
    background: white !important;
  }

}
.fc-col-header-cell.fc-day {
  background: var(--bg) !important;
}

.fc-col-header {
  width: 24%;
  /*min-width:1000px !important;*/
}

.fc .fc-timegrid-slot-label-cushion,
.fc-col-header-cell-cushion {
  color: var(--green);
  font-weight: bold;
  font-size: 15px;
  cursor: default;
}

.fc-col-header-cell-cushion{
  display: flex !important;
  flex-direction: column;
  justify-content: flex-end;
  height: 45px;

  &:hover {
    text-decoration: none !important;
  }

  @media (max-width: 991px) {
    height: 41px;
    /* margin-inline-end: 26px; */
  }

  span {
    font-size: 14px;
    margin: 0 2px;
  }
  .holiday-name {
    font-size: 10px;
    line-height: 0;

    @media (max-width: 991px) {
      font-size: 8px !important;
      line-height: 6px !important;
    }
  }

  .fc-timeGridWeek-view &{
    span{
      @media (max-width: 991px) {
        font-size: 12px !important;
      }

      &:first-child{
        font-size: 26px;
        margin: 0;
      }
    }

  }
}

.home-container .fc-col-header-cell-cushion {
@media (min-width: 992px)
{
    width : max(274px, calc( var( --cal-width ) - 26px));
  }

}

.home-container .fc-col-header-cell-cushion span {
  font-size: 20px;
}


/*fc-col-header-cell.fc-day.fc-day-today {
  background: var(--bg) !important;
}*/


.fc-timegrid-slot-label,.fc-timegrid-axis {
  background: var(--bg);
}

.calendar-wrap {
  padding-inline:3px;
  /*padding-top: 48px;*/
  margin-top: 116px;

  @media (max-width: 991px) {
    padding: 0;
    /*padding-top: 16px;*/
    margin-bottom: 110px;
    margin-top: 130px;
  }

  .home-container & {
    padding: 0;
    padding-top: 0;
    margin-top: 0;

    @media (max-width: 991px) {
      padding-top: 44px;
      /*padding-top: 29px;*/
      margin-bottom: 40px;
    }
  }
}

.fc-day.fc-timegrid-col {
  box-shadow: 30px 0 175px -21px #00000017;
}

.fc-dayGridMonth-view {
  margin-top: 49px;
}

.event-square {
  font-size: 14px;

  .OwnerHasUsers & {
    font-size: 12px;
  }

  .short-length &{
    font-size:10px !important;
    margin: -4px 0;
    white-space: nowrap;
    overflow: hidden;
  }
    
}

.home-container .event-square {
  @media (max-width: 991px) {
    font-size: 16px;
  }
}

.input-group{
  flex-wrap: nowrap;
}





.gen-tbl-search {
  max-width: 250px;
  display:flex;
  align-items: center;
  margin-inline: 5px 0;
}

.input-number { /* for minuses */
  direction: ltr;
  text-align: left !important;

  [dir=rtl] &{
    text-align: right !important;
  }
}


[dir=ltr]{
  .fa-angle-double-right,.fa-angle-double-left,.fa-arrow-circle-left,.fa-arrow-circle-right,.fa-arrow-left,.fa-angle-right, .fa-angle-left,.fa-arrow-right{
   transform: scale(-1);
 }
}


.input-group .form-control:first-child,
.input-group-addon:first-child{
  border-start-start-radius: 4px !important;
  border-end-start-radius: 4px !important;
  border-start-end-radius: 0 !important;
  border-end-end-radius: 0 !important;
}
.input-group .form-control:last-child,
.input-group-addon:last-child,
.gen-autocomplete{
  border-start-end-radius: 4px !important;
  border-end-end-radius: 4px !important;
  border-start-start-radius: 0 !important;
  border-end-start-radius: 0 !important;
}



.invoice-payment-details{
  th,td {
    font-size: 14px;
    padding: 2px !important;
  }
}


.btn-bottom {
  margin-block: 10px;

  .btn {
    margin-inline: 3px !important;
   /* min-width: 90px;*/
  }
}



.display-number {
  unicode-bidi: bidi-override;
  direction: ltr;
  text-align: right
}

ul{
  .editor &,.mixed-list & {
    padding: revert;

    li{
      list-style: revert;
    }
  }
}

ol li{
  list-style: revert;
}

.autocomplete-inactive {
  opacity: 0.5;
}

.fc-timegrid-event-harness-inset .fc-timegrid-event,
.fc-timegrid-event.fc-event-mirror {
  box-shadow: none !important;
  padding: 4px;

  &:hover {
    filter: brightness(0.7);
  }
}
  .fc-timeGridWeek-view .fc-timegrid-col .fc-event {
  @media (max-width: 991px) {
    padding: 0;
  }
}


span.event-hours {
  background: #ffffff1c;
  padding: 0 4px;
  border-radius: 4px;
  margin-inline-start: -4px;
}

.chosen-items {
  border: 1px var(--green) solid;
  border-radius: 6px;
  background-color: var(--bg);
  margin: 10px 0;
  display: inline-flex;

  &>div {
    margin: 8px;
    border: 1px var(--green) solid;
    border-radius: 4px;
    background-color: white;
    padding: 4px 12px;
    display: inline-flex;
  }
}



.cal-month-holiday {
  width: 100%;
  display: flex;
  justify-content: space-between;

  &>div{
    &:first-child {
      margin: 0 10px;

      @media (max-width: 991px) {
        font-size: 8px;
      }
    }
  }
}



.editor-tbl{
  td{
    width:100px;
    height:40px;
    border:1px #ccc solid;
    padding:2px;
    white-space: nowrap;
  }
}
.planted-tbl{
  width:90%;
  margin-inline: 20px;

  td,th{
    height:40px;
    border:1px #ccc solid;
    padding: 10px;

  }
}

.ltr{
  direction:ltr;
}

.network-error {
  height:100vh;
  display:flex;
  align-items:center;
  padding:0 20%;
  text-align:center;
  font-size: 32px;

  @media (max-width: 991px) {
    font-size: 24px;
  }
}

.btn-group{
  display: block;
}

.spacer{
  height:20px;
}


:focus {
  outline: none !important;
  /*box-shadow: none !important;*/
  /*box-shadow: 0 0 0 2px rgb(95 166 5 / 25%) !important;*/

}
button:focus,
a:focus,
a:hover,
a:visited,
input,
input:hover,
input:focus,
input:active,
select {
  text-decoration: none;
  outline: none;
}
select{
  width:fit-content !important;
  padding-inline-end: 20px !important;
  max-width: 200px !important;
  appearance: auto !important;

  @media (max-width: 991px) {
    width: 150px !important;
  }
}

.text-agree {
  font-size: 16px;
  font-weight: normal;
  color: rgba(95, 166, 5, 0.7);
  margin-inline-start: 20px;
  padding-top: 5px;

}

.error {
  color: white;
  background: var(--red);
  margin-top: 3px;
  border-radius: 4px;
  padding-inline: 2px;
  /*color: deeppink;*/
  margin-bottom: 2px;
  font-size: 16px;
}
.error-soft {
  color: var(--black);
  background: white;
  margin-top: 3px;
  border-radius: 4px;
  padding-inline: 2px;
  /*color: deeppink;*/
}

.agree-to-terms {
  width: 25px;
  height: 25px;
  /*opacity: 0.3;*/
  border-radius: 5px;
  background-color: var(--green);
  position: initial;
  transform: scale(1.5);
  padding: 10px;
  margin: 0;

  @media (max-width: 767px) {
    transform: initial;
  }

}




.gen-ac-opt{
  display: flex;

  li:hover &{
    i{
      color: white !important;
    }
  }

  & >div{
    &:first-child{
      width:22px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-inline-end: 5px;
    }
  }
}

tr{
  &.grey{
    background-color: #f9f9f9;
  }
}

.subtotal{
  background-color: #eee !important;
}
.highlight-row{
  background-color: #98ef29;
}
.highlight-row2{
  background-color: #FF5733;
}
.loading-spinner{
  text-align: center;
  i{
    color: var(--green) !important;
    font-size: 150px;
  }
}

i{

  &.fa,&.fas {
    color: #838383;
    padding: 0 1px;
    margin: 0;

    .searchClear &{
      color: #ccc;
    }
    &.fa-brands.fa-whatsapp {
      font-size: 18px;
      color: var(--green);
    }
  }


  &.fa{
    .actions &{
      cursor: pointer;
      margin:auto 4px;
    }
    &.fa-solid{
      &.fa-arrows-rotate {
        font-size: 10px;
        margin-inline-start: auto;

        @media (max-width: 576px) {
          font-size: 8px;
        }
      }
    }
    @media (max-width: 991px) {
      margin-inline: 4px;
    }
  }

  &.fas{
    &.fa-angle-right,&.fa-angle-left{
      color: white !important;

      .items &{
        color: var(--green) !important;
      }
    }
  }

  .refresh &{
    position: absolute;
    top: 12px;
    right: 6px;
    color: white;
    cursor: pointer;
  }

  .sidebar-item &{
    margin: 0;
    width: 30px;
  }

  .ac-tag &{
    margin-inline-start: 6px;
    color: white;
    cursor: pointer;
    vertical-align: middle;
  }

  .followups-loader &{
    font-size: 30px;
  }
  .image-gallery &{
    font-size: 40px;
  }
  .submit-comment &{
    padding-top: 0;
    font-size: 20px;
  }

  .dir-btn &{
    margin: 0;
  }

  app-gen-file-input &{
    &.fa-spinner {
      margin: 0 10px;
      color: white;
    }
  }
  &.pre-icon{
    /* margin-inline-end:6px; */
    /* height: 23px; */
    display: flex;
    align-items: center;

  }
  &.post-icon{
    /* margin-inline-start:6px; */
  }
  /* &:hover {
    @media (max-width: 991px) {
      color: white;
      filter: drop-shadow(0 0 10px black) drop-shadow(0 0 5px black) drop-shadow(0 0 5px #00000059);
      transition: 0.2s ease-in-out;
    }
  } */
}



.zone{
  height:40px;
  display: flex;
  width:250px;
  align-items: center;
  padding:0px 10px;
  border-radius: 10px;
  margin:4px 0;
  position: absolute;

  cursor: pointer;

  i{
    cursor: pointer;
    margin:0 4px;
    font-size:20px;
  }
}



app-icon{
  line-height: 0;
}

.separation-line {
  border-bottom: 1px solid var(--grey);
  /*margin: 20px 0;*/
  margin: 20px 2px 20px 20px;
}

p{
  .heading{
    font-size: 20px;
    font-weight: bold;
    text-decoration:underline;
  }
}

.payment-form{
  min-height: 100svh;
  max-width: 991px;
  display: grid;
  align-content: space-between;
  justify-items: center;
  gap: 30px;
  padding-top: 60px;
  grid-template-columns: 1fr;

  @media (min-width: 991px) {
    grid-template-columns: 1.7fr 1fr;
  }

  button {
    width: 100%;
    border-radius: 5px;
    font-size: 35px;
    margin-block-end: 10px;

    @media (max-width: 991px) {
      font-size: 18px;
    }

  }
  .payment {
    margin: 0 auto 0;
    padding: 40px;
    background-color: white;
    line-height: 1.6;
    display: grid;
    gap: 10px;

    @media (max-width: 991px) {
      margin-inline: 15px;
      width: 96%;
    }
  }

  &.container-fluid {
    @media (min-width: 992px) {
      /* padding: 20px; */
      /* font-size: 20px; */
    }
  }

  footer {
    border-top: 1px solid #eee;
    display: flex;
    justify-content: center;
    padding-block: 10px;
    width: 100%;

    @media (min-width: 1200px) {
      grid-column: 1 / 3;
    }
  }

  .help-btn {
    display: none;
  }
}
.modal-btns{
  text-align:end;
  margin-top:20px;
  margin-bottom: 2px;

  button{
    margin-inline: 3px !important;
    @media(min-width: 576px){
      min-width: 90px;
    }

  }

}

.login-page{
  .form {
    & select, input:not([type="checkbox"]) {
      font-size: 16px;
      letter-spacing: -0.32px;
      padding-right: 18px;
      width: 100%;
      height: 40px;
      border-radius: 4px;
      background-color: #ffffff;
      /*border: none;*/
      /*color: rgba(47, 47, 47, 0.7);*/
      color: var(--black);
      /*border: 2px solid  var(--orange);*/
      box-shadow: inset 0 -1px #3534342b;

      &:focus {
        box-shadow: 0 0 0px 2px var(--orange);
      }
    }
    & button{
      &.subm {
        padding: 0;
        border: 0;
        line-height: 53px;
        width: 100%;
        /*border-radius: 5px;*/
        background-color: var(--green);
        color: #ffffff;
        font-size: 35px;
        font-family: 'Heebo', sans-serif;
        border-radius: 4px;
        /*border-end-end-radius: 20px;*/
        /*margin-top: 30px*/
        margin: 10px 0 30px;
        /*border: 2px solid white !important;*/
        box-shadow: inset 0 -2px #3534342b;
      }
    }
  }

  button{
    app-icon{
      font-size:24px;
      vertical-align: inherit;
    }
  }
}
app-otp{
  button{
    font-size: 28px !important;
    height: inherit !important;
    padding-inline: 14px !important;
  }
}

.fa-plus-circle{
  &::before {
    color: var(--green);
  }
}
.up-down-wrapper{
  display: flex;
  flex-direction: column;

  i:hover {
    color: var(--black);
    cursor: pointer;
  }
}
::-webkit-scrollbar {
  width: 10px;
  height: 5px;
  background: var(--bg);
}

::-webkit-scrollbar-button {
  /* background: transparent; */
  height: 0;
}


::-webkit-scrollbar-thumb {
  /* background: var(--orange); */
  background: linear-gradient(45deg, #e1e1e1, #c5c5c5);
  height: 20%;
  border-radius: 10px;
}

.text-end{
  text-align: end !important;
  /*margin-block: auto;*/
}
.ms-auto{
  margin-inline-start: auto !important;
}
.flex-inline-end{
  display: flex;
  justify-content: end;
}

.expanded-div {
  margin: 10px;
  display: flex;
  flex-wrap: wrap;
  margin-inline-start: 33px;

  & > div {
    margin: 4px 3px;
    width: 18%;

    @media (max-width: 767px) {
      width: 48%;
    }

    & > label {
      color: var(--green);
      display: block;

      &.transparent{
        color:transparent;
      }
    }
    &.add-btn {
      margin-top: 18px !important;
    }
  }
  .actions{
    
    @media (max-width: 767px) {
      width:5% !important;
      min-width: 5%;
      display: flex;
      align-items: flex-end;
    }
  }
}
.action-fields{
  width:24px;
  padding-inline: 0;

  @media (max-width: 767px) {
    width:8%;
  }
}

.sort-action {
  align-self: center;
}


/* @media Start */
/* =========================================================================
    Responsive Design
   =========================================================================
 */

/*
320px — 480px: Mobile devices.
481px — 768px: iPads, Tablets.
769px — 1024px: Small screens, laptops.
1025px — 1200px: Desktops, large screens.
1201px and more — Extra large screens, TV.
*/
/* ===================================================
                    Helper Classes
====================================================== */
.cal-title {

  color: var(--green);
  font-size: 24px;
  font-weight: 700;
  cursor: default;
  margin: auto;
  height: 30px;
  align-items: center;
  @media (max-width: 991px) {
  }
  [dir=ltr] &{
    @media (min-width: 1400px) {
      font-size: 20px !important;
    }
  }
}

.d-xxxl-inline-block {
  @media (min-width: 1500px) {
    display: inline-block !important;
  }
}

.bottom-row-buttons{
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  flex-wrap: wrap;

    @media (991px < width) {
    flex-wrap: nowrap;
  }
  /* @media (min-width: 576px) {
    margin-inline: -20px;

  } */
  /* button {
    @media (max-width: 1400px) {
      padding-inline: 7px;
    }
    @media (max-width: 1200px) {
      height: 48px;
      padding: 1% 5%;
    }
  } */

  & button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    width: 100%;
    height: 37px;
    text-wrap: nowrap;
  }

}

.btns-with-icon{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.home-meeting-wrapper .bottom-row-buttons {
  justify-content: space-between;
}

.action-buttons {
  padding: 0;
  display: flex;
  gap: 2px;

  @media (max-width: 768px) {
    display: grid;
    grid-template-columns: repeat(4, 1fr);

    width: 100%;

    & button {
      flex-direction: column;
      text-wrap: wrap;
      height: 100%;

      @media (max-width: 424px) {
        word-spacing: 20px;

      }
    }

    .pre-icon {
      height: 23px;
    }
  }

  @media (991px < width < 1401px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

}

.no-small-mob{
  @media (max-width: 665px){
    display: none !important;
  }
}
.no-very-small-mob{
  @media (max-width: 768px) or (992px < width < 1200px) {
    display: none !important;
  }
}

.confirm-modal-vert-center{
  @media (min-width: 992px) {
    transform: translateY(calc(50vh - 120px - 100px)) !important;
  }
}

.LOGO {
  @media (max-width: 991px) {
    width: 115px;
  }
}


.container-fluid {
  @media (max-width: 991px) {
    padding-inline: 0;
    margin-inline: 0;
  }
}
.no-mob {
  @media (max-width: 991px) {
    display: none !important;
  }
}

.fc-timeGridWeek-view{
  @media (max-width: 991px) {
    span.event-hours,.type-icon {
      display: none;
    }

    .event-square {
      font-size: 12px;
    }
  }
  @media (max-width: 767px) {
    .event-square{
      line-height: 11px;
    }
  }
}
.fc .fc-scroller-harness {
  /* @media (max-width: 991px) {
    margin-top: 17px;
  } */
}
span.low_opacity {
  color: #2f2f2fc9 !important;
  font-weight: 700;
  display: flex;
  align-items: center;
  gap: 1px 6px;
  flex-wrap: wrap;
}
.yes-no{
  button{
    cursor: pointer;
    font-size: 20px;
    font-weight: 500;
    width: 24px;
  }
}
.person-side-item{
  display:flex;
  margin-bottom:5px;
  min-height:60px;
  width: 100%;
  margin-bottom: 7px;
  border-radius: 4px;
  background: white;

  &:hover, &.active{
    background: #ecefe9;
    /*argin-inline-start: -10px;*/
  }
  & > div{
    display:flex;
    align-items:center;

    &:first-child{
      /*width:20%;*/
      width: 65px;
      justify-content:center;
      /*border-end-end-radius: 25px;*/
      border-radius: 0 4px 4px 0;

      flex-direction:column;
      align-items:center;
      padding:4px;
      font-size:10px;

      & > div{
        text-align:center;
      }
    }

    /*background-color:white;*/
    &:last-child{
      /*flex-grow:1;*/
      width:100%;
      /*background-color:white;*/
      padding-inline:8px;
    }
  }
}

.tests-autotext-menu-list.status{
  background: var(--green);
  color: white !important;

  &:hover{
    background: var(--dark-green);

  }
}

.canvas-write{
  border:1px solid black;
}
.bkmvdata{
  tr{
    background-color: #F0FFFF;

    &:first-child{
      background-color: #FF7A5A;
      font-weight: bold;
    }
  }
}

.confirm-modal,.instructions{
  p{
    min-height:120px;
    font-size: 16px;
    padding-top:30px;
  }
}

.checkboxes{
  span{
    cursor: pointer;
  }
  table{
    width:300px;
    margin-inline-start:20px;
  }
}
.section-row {
  cursor: pointer;
  min-height: 30px;
  display: flex;
  padding-inline-start: 10px;
  margin-inline-end: 10px;
}
.followups-list-tr {
  td{
    width: 100px;
    max-width: 100px; /* add this */
    white-space: nowrap;
    overflow: hidden;
  }
}
.email {
  direction: ltr;
}
.price-width{
  max-width: 140px;
}
.time-width{
  max-width: 140px;
}
.length-width{
  max-width: 100px;
}

.no-border-end{
  border-inline-end: 0;
}

footer {
  /*margin: 20px 0 10px;*/
  font-size: 10px;
  color: #7f8082;
}
.footer-bottom-mobile{
  @media (max-width: 991px){
    margin-bottom: 65px;
  }
}

.table-display{
  h4{
    margin-top: 20px;
    padding-top: 10px;
    border-top: 1px #e5e5e5 solid;
  }
}
/*.table-responsive {
  margin-block: 20px;
  transform: rotateX(180deg);
  & table {
    transform: rotateX(180deg);
  }
  ::-webkit-scrollbar-thumb {
    background: var(--green);
  }
}*/
.fixed-corner {
  position: fixed;
  z-index: 99999999;
}
.help-btn {
  left: 30px;
  bottom: 5px;
  background: #c8c8c8;
  border: 1px #ccc solid;
  border-radius: 25px;
  padding: 0 10px;
  cursor: pointer;
  color: white;

  @media (max-width: 991px) {
    left: 2px;
    bottom: 69px;
    background: var(--bg)00;
    color: white;
    font-weight: 900;
    background: var(--orange);
    border-radius: 0 20px 20px 0;
    border: 0;
  }

  &:hover {
    background: #bcb8b8;
  }
  p {
    font-size: 20px;
    margin: 0;
  }
}
.app-lang-select {
  left: 90px;
  bottom: 20px;

  select {
    background: #eee;
    border: 1px #ccc solid;
    border-radius: 12px;
    padding: 3px 8px;
    font-size: 15px;
  }
}
.overlay-spinner {
  z-index: 10000;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;

  i{
    color: var(--orange);
    font-size: 200px;
  }
}
.approve-cookies {
  bottom: 0;
  width: 100%;
  padding: 40px;
  background: wheat;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: fixed;
  align-items: center;
  z-index: 999999999999999999999999999999999999999999999999999;

  button {
    width: 20%;
    margin-top: 40px;
    padding: 20px 0;
    font-size: 40px;
    background-color: whitesmoke;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.refresh {
  position: absolute;
  top: 200px;
  width: 80%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100000000000;
  background-color: var(--green);
  color: white;
  padding: 6px 30px;
  border-radius: 10px;
  right: 10%;
}

.network-offline{
  position: fixed;
  top:0;
  left:0;
  width:100vw;
  background-color: var(--red);
  color: #fff;
  display: flex;
  justify-content: center;
  z-index: 999999999999999;
  padding: 6px;
  font-size: 16px;
  font-weight: 900;
  box-shadow: 0 8px 10px #3534342b;
}
.router-cont {
  margin-top: 86px;
  min-height:calc(100svh - 86px);

  @media (min-width: 1400px) {
    margin-inline: 10px;
  }
}
.subj{
  h3{
    background-color: #8fdf82;
  }
  & > div{
    margin-bottom: 20px;

    & > div{
      &:first-child{
        cursor: pointer;
        display: flex;
        align-items: center;

        & > div{
         /* display: block;*/
          color: var(--green);
        }
      }
    }
  }
}
.imp-p{
  .parent {
    transform: rotateX(180deg);
    overflow-x: auto;

    .child {
      transform: rotateX(180deg);

      table{
        th,td {
          height: 30px;
          vertical-align: middle !important;
        }
        .btn{
          margin:2px 0;
        }
      }
    }
  }
}
.static{
  margin-block: 2px;
  padding: 4px;
  min-height: 30px;
  border-radius: 0.25rem;
  /*background: #f9f9f9;*/
  cursor: default;
}
.followups-update-list{
  th{
    position: sticky;
    top: -5px;
    background-color: white;
    z-index: 999999999;
  }
}
.description-text{
  padding-left: 70px;
  padding-right: 70px;

  @media (max-width: 991px) {
    padding-left: 10px;
    padding-right: 10px;
  }
}
img{
  &.fp-image {
    max-height: 100px;
    margin: 10px;
    cursor: pointer;
    max-width: 300px;
  }
}
.items-container{
  position: absolute;
  background-color: white;
  z-index: 13;
  margin-top: -8px;
  min-width: 200px;
  border-radius: 8px;

  .patient-row {
    cursor: pointer;
    font-size: 14px;
    padding:6px 10px;
    border-bottom: var(--bg) 1px solid;

    &:last-of-type {
      border-bottom: 0;
    }
  }
}
.finresource-translation{
  tr{
    td{
      &:nth-of-type(3){
        direction: ltr;
      }
    }
  }
}
.circles{
  margin-block-start: 20px;
  display: flex;
  & > div{
    margin-inline: 2px;
    border-radius: 50%;
    border: 1px solid #ccc;
    width:20px;
    height:20px;

    &.active{
      background-color: var(--green);
      border-color: var(--green);
    }
  }
}
.reports{
  label {
    margin: 0 4px;
    cursor: pointer;
  }
  input{
    &[type=checkbox]{
      vertical-align: -1px;
    }
  }
}
.params-holiday{
  td {
    vertical-align: middle !important;
  }
  h4{
    background-color: #8fdf82;
    padding: 5px 20px;
  }
}
.mobile-tabs{
  @media (max-width: 991px){
  position: fixed;
  width: 100%;
  /*background: var(--bg);*/
  background: linear-gradient(135deg,#ffe11e,#ff44bb);
  z-index: 900;
  /*top: 45px;*/
  bottom: 64px;
  display: flex;
  justify-content: space-around;
  margin-block: 0;
  height: 40px;
  display: flex;
  align-items: flex-end;
  justify-content: space-around;
  padding-inline-end: 30px;
  border: 1px solid var(--bg);
  border-radius: 10px 10px 0 0;
  /*box-shadow: 0 -3px 11px #0000004f inset;*/

  li {
    /*width: 47%;*/
    width: 28.5%;
    width: -webkit-fill-available;
    /*border: 1px solid var(--green);*/
    font-size: 16px;
    padding: 2px 0;
    /*margin: 0 3px;*/
    /*border-radius: 2px;*/
    cursor: pointer;
    /*color: var(--green);*/
    color: white;
    font-weight: 700;
    letter-spacing: 1px;
    border-bottom: transparent 6px solid;
    margin-inline: 3px;

    &.active {
      /*background-color: var(--bg);*/
      /*color: var(--orange);*/
      border-bottom: white 6px solid;
    }

  }

  &.three-tabs{
    li {
      /* width: 30.1%;*/
      /*width: 28.5%;*/
    }
  }
  }
}
.patient-banner {
  padding:0;
  padding-inline: 14px 3px;
  background-repeat: no-repeat;
  background-size: cover;
  font-weight: bold;
  position: sticky;
  top: 86px;
  z-index: 900;

  @media (max-width: 991px){
    padding: 0 !important;
    position: fixed;
    /*top: 88px;*/
    /*margin: 0 4px;*/
    /*width: calc(100vw - 24px);*/
    width: 100%;
    top: 50px;
    height: 45px;
    margin-inline-start: -3px;
  }

  .modal-content &{
    position:inherit !important;
  }
  .home-container &{
    padding-inline:0;

    @media (min-width: 992px) {
      margin-inline: 15px 0;

    }

  }

  .patient-banner-background {
    border-radius: 5px;
    height: 55px;
    box-shadow: inset 0 -1px #3534342b;

    @media (max-width: 991px){
      height: 45px;
      border-radius: 0;
    }
  }
  .img-overlay-gradient {
    position: absolute;
    width: 99%;
    height: 55px;
    color: #fff;
    padding-inline-start: 21px;
    border-radius: 4px;
    box-shadow: 0 12px 36px -15px #3534344d;
    text-shadow: 0px 2px 0px #35343433;
    white-space: nowrap;

    @media (max-width: 991px){
      height: 45px;
      border-radius: 0;
    }

    @media (max-width: 575px) {
      padding-inline-start: 7px;
    }

    span {
      position: absolute;
      line-height: 51px;
      font-size: 42px;
      text-shadow: 3px 1px 0 #3534342b;

      @media (max-width: 991px){
        font-size: 35px;
        /*line-height: 20px;*/
        /*margin-top: 6px;*/
      }
    }
  }

}
.followup-patient-on-home{
  @media (min-width: 992px){
    /* width: 76%; */
    width: var(--followup-patient-width);
  }

}
.green-title {
  margin: 0;
  font-size: 32px;
  font-weight: bold;
  text-align: start;
  color: var(--green);
}
.time-allocation{
  h2 {
    color: var(--green);
    font-size: 30px;
    display: inline;
  }
}
@media (max-width: 991px) {
  .time-allocation .cal-btn {
    font-size: 14px;
  }
}
.test-manage{
  background-color: white;
  padding:10px;

  .new-row {
    background: #e8fffe !important;
  }
  .child-tr{
    padding-inline-start: 20px;
  }
  .name-td{
    min-width:150px;
    width:200px;
  }
}
.terms-sms{
  direction:rtl;
  padding:15px;

  ol{
    direction:rtl;
  }
}
.taxes-report{
  th,td{
    font-size:15px;
    padding:4px 0 !important;
  }
}
.sub-user-patients{
  .subtotal{
    height:50px;
  }
}
.profitloss{
  table{
    table-layout:fixed;

    tr{
      th,td {
        width: 80px;

        &:first-child{
          width: 200px;
        }
      }
    }
  }
}
.userdetails-lines{
  background-color: white;
  padding:10px;

  td{
    padding-block: 4px !important;
  }
}
.patient-details-fields{
  table{
    width:300px;
    margin-inline-start:20px;

    td{
      vertical-align: middle;
    }
  }
}
.reports-container{

  .table-rows{
    margin-top: 10px;
    line-height: 1.1;

    th,td {
      /* width: 10%; */
      font-size: 12px;
      border-bottom: 1px solid grey;
      padding: 4px 2px !important;
      vertical-align: middle;
    }
    tr {
      border-bottom: 1px solid grey;
    }
    .totals-row {
      font-weight: bold;
      border-width: 2px;
      background-color: #ccc;
    }
  }

  .h5{
    margin-inline-start: 10px;
  }
  .vis-field{
    display: flex;
    margin-inline-start: 10px;
    margin-bottom: 10px;

    & > div{
      margin-inline-end: 8px;
      display: flex;
      align-items: center;

      & > span{
        margin-inline-start: 8px;
        cursor: pointer;
      }
    }
  }

  .report-settings {
      padding-bottom: 5px;

  }

  .margin-btns{
    button {
      margin: 2px;
    }
  }
}

.showVis{
  background: var(--light-green);
  border-radius: 0 0 4px 4px;
  padding: 4px;
  margin-bottom: 5px;
  box-shadow: inset 0 -1px #3534342b;
  overflow-x: auto;
  position: fixed;
  z-index: 2;
  box-shadow: 0 12px 32px -15px #00000059;
  max-width: 95svw;
}


.wysiwyg{
  background: white;
  /* margin-bottom: 30px; */


  .editor {
    width: 100%;
    padding: 4px;
    border: 1px solid transparent;
    border-right: 0;
    border-left: 0;
    background-color: white;
    overflow: auto;
    box-shadow: inset 0 -1px #3534342b;
    border-radius: 0 0 4px 4px;
    cursor: text;
    padding-inline: 20px;
    box-shadow: 0px 12px 36px 0px #3534340f;

    &[title="רשומה רפואית"] {
      height: 500px;
    }

  }
  select{
    padding:0 !important;
    border: 1px var(--grey) solid;
    color: #838383;
    width: 50px !important;
    background: transparent;
    border-radius: 4px;

    @media (max-width: 991px){
      margin-inline-end: 15px;
    }

    @media (max-width: 420px){
      font-size: 14px;

    }
  }

  .toolbar {
    display: flex;
    /*width: 100%;*/
    /*border: 1px #eee solid;*/
    border: 0;
    padding: 4px;
    align-items: center;
    border-radius: 4px 4px 0 0;
    margin-inline: 10px;
    border-bottom: 1px var(--grey) solid;

    button,input {
      border: 0;
      border-radius: 2px;
      cursor: pointer;
      background-color: transparent;
      font-size: 10px;
      color: #838383;

      @media (max-width: 991px){
        margin-inline-end: 13px;
        font-size: 14px !important;
      }
      @media (max-width: 420px){
        font-size: 16px !important;
      }



    }


    input {
      width: 20px;
      height: 20px;
    }

    button:hover,button.active,input:hover{
      color: #333;
      background-color: #e6e6e6;
      border-color: #adadad;
    }
    button{
      display: flex;
      align-items: center;

      @media (min-width: 992px){
        margin: 0 3px;
        display: flex;
      }
      &.color {
        width: 15px;
        height: 15px;
        border: 1px var(--grey) solid;
        border-radius: 50%;

      }
      &.active {
        box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
      }
    }
    & > div > button.align-select {
        border: 1px solid var(--grey);
        border-radius: 4px;
        padding-block: 6px;

        &:after{
            content: '\f078';
            font-family: FontAwesome;
            font-weight: 900;
            color: var(--black);
            margin-inline-start: 5px;
          }
     }
  }


  .align-holder,.note-holder,.tbl-holder {
    position: absolute;
    background: white;
    display: inline-block;
    border: 1px #eee solid;
    border-radius: 4px;
    padding: 10px;
    z-index: 100;
  }
  .note-holder{
    button {
      width: 20px;
      height: 20px;
      border: 1px white solid;
    }
  }
  .align-holder{
    display: flex;
    flex-direction: column;
    padding: 7px;
    gap: 10px;
    position: absolute;
    top: 13px;
    border: 1px solid var(--grey);
    border-radius: 10px;
  }

  .note-holder > div {
    display: flex;
  }


  .bottom-bar {
    width: 100%;
    height: 10px;
    background-color: #eeeeee82;
    cursor: n-resize;
    text-align: center;
    border-end-start-radius: 4px;
    border-end-end-radius: 4px;

    &:hover {
      filter: brightness(0.9);
    }
  }


  .editor:empty:not(:focus):before {
    content: attr(data-text);
    opacity: 60%;
  }

  .hover-gen{
    position: absolute;
    background-color: white;
    border-radius: 4px;
    border: 1px #ccc solid;
    display: flex;
    padding:4px;
    justify-content: space-evenly;
    height:30px;
    font-size: 10px;
    width: 200px;
  }

  .tbl-holder{
    & > div{
      display: flex;
      flex-direction: column;

      & div{
        display: flex;

        & div{
          border: 1px #ccc solid;
          height:10px;
          width:10px;
          margin:1px;

          &.on{
            background-color: red;
          }
        }
      }
    }
  }
  .tbl-hover{
    width:100px;
    z-index: 1000000;
    cursor: pointer;

    & > div{
      width:45%;
      height:100%;
      display: flex;
      justify-content: center;
      align-items: center;

      & > div{
        z-index: 1000001;
        margin-top:40px;
        right:0;

        & > div{
          height:100%;
          display: flex;
          justify-content: center;
          align-items: center;
          padding:4px;
        }
      }
    }
  }

  &.maximized{
    position: fixed;
    z-index: 9999999999999999;
    top: 0;
    right: 0;
    width: 100%;
    padding-inline: 40px;
    height: 100vh;
    background: var(--bg);

    .editor{
      width:100%;
      height:calc(100vh - 130px) !important;
      border-end-start-radius: 4px;
      border-end-end-radius: 4px;

    }
    label{
      font-size: 26px;
      padding-top: 3px;
      color: var(--green);
      height:24px;
      margin: 0;
    }
  }
}
.no-followup-title {
  font-size: 26px;
  font-weight: bold;
  text-align: center;
  color: var(--green);
}
.no-followup-container{
  padding: 60px;
}
.document-div{
  display: flex;
  justify-content: space-between;
}
.center-area {
  margin-top: 10px;
  /* padding-inline-end: 15px; */
  min-width: calc(100% - 400px);

  @media (max-width: 991px){
    margin-top: 8px;
    margin-bottom: 30px;
    padding-inline-end: 0;
  }
}
.disabled-input {
  background: #eee;
  direction: ltr;
  text-align: right;
  cursor: default;
}



.person-side-items{
  display: flex;
  flex-direction: column;
  font-size:12px;
  line-height: 1.1;
  width:100%;
  padding-inline-end: 6px;
  padding-block: 5px;
  color: var(--black);


  .tests{
    display: flex;

    & > div{
      &:first-child{
        width:50%;
      }
    }
  }
}
.contactway{
  font-size: 14px;
}
.back-to-details{
  font-size: 10px;
  margin-inline-start: 5px;
}
.totals-format{
  & > div{
   /* margin:10px;*/
  }
  span{
    direction: ltr;
    display: inline-block;
  }
}
.below-banner {
  margin-top: 10px;

  @media (max-width: 991px) {
    margin-top: 100px;
  }
}

.person-content{
  padding-inline: 14px 3px;

  @media (max-width: 991px) {
    padding-inline: 0;
    padding-bottom: 35px;
  }

  .home-container &{
    /*padding-inline: 9px 6px;*/
    /* padding-inline: 9px 0; */
    padding-inline: 0;

    @media (max-width: 991px) {
      padding-inline: 0;
    }
  }
}


.mb20{
  margin-bottom:20px;
}

.followup-image{
  max-width:500px;
}
.followup-image-div{
  text-align:center;
}
.logo-img{
  max-height:150px;
  max-width:250px;

  .wide-logo &{
    max-width: 100%;
    max-height: inherit;
  }

  @media (max-width: 991px) {
    max-height:20px;
  }

}
.signature-img{
  max-width:150px;

}
#title-text{
  font-size:16px;

  @media (max-width: 991px) {
    font-size:10px;
  }
}
#title-text-document{
  font-size:14px;
  font-weight:bold;
  text-decoration: underline;

  @media (max-width: 991px) {
    font-size:8px;
  }
}
#preview-finresource{
  margin:20px auto 0;
  width:700px;
  border: 1px solid #e5e5e5;
  padding: 8px;

  table{
    width:100%;

    th,td{
      font-size:14px;
      padding: 2px !important;
      height:30px;

      @media (max-width: 991px) {
        font-size:8px;
      }
    }
  }

  hr{
    margin-bottom:4px;
    margin-top:4px;
  }

  #date-text,.sig-text,.header-span,.for{
    font-size:14px;

    @media (max-width: 991px) {
      font-size:8px;
    }
  }
  .for{
    font-weight:bold;
  }
  .notfor{
    font-weight:lighter;
  }
  .emp-number {
    font-weight: bold;
  }
  .highlight{
    background-color:#F5F5F5;
  }
  .areaRow{
    margin-bottom:20px;
  }
}

table.area-matrix{
  width:100%;

  td{
    width:100px;
    height:100px;
    border: 1px solid #e5e5e5;
    padding:10px;

    & > div{
      width: fit-content;
      background-color: var(--light-green);
      padding:6px;
      border: 1px solid #e5e5e5;
      border-radius: 10px;

      & > div{
        display: flex;
        justify-content:center;
        align-items:center;

        &:first-child,&:last-child{
          cursor: pointer;
        }
        &:nth-child(2){
          div{
            &:first-child,&:last-child{
              cursor: pointer;
            }
            &:nth-child(2){
              padding:10px;

              .error{
                margin:0;
                margin-top:10px;
              }
            }
          }
        }

      }
    }
  }
}
.image-gallery {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  @media (max-width: 991px) {
    flex-wrap: wrap;
  }

  img {
    max-width: 90%;

    @media (max-width: 991px) {
      order: 2;
      width: 100%;
      max-width: 100%;
    }
  }

  div {
    padding-top: 10px;
    width: 5%;
    display: flex;
    justify-content: center;

    @media (max-width: 991px) {
      order: 1;
    }
  }
}
.image-container{
  display: flex;
  flex-wrap: wrap;

  & > div{
    padding-inline-end:10px;
    position: relative;
    cursor: pointer;


    & > div{
      position: absolute;
      /*background-color: #FFFFFFAA;*/
      width: 34px;
      height: 34px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 0;
    }
  }
  img{
    max-height: 100px;
    max-width: 150px;
    margin-inline: 35px;
  }
}


/*.custom-menu{
  .fa-bars {
    color: white;
  }
} */
.custom-menu {
  position: absolute;
  top: 20px;
  right: 0;
  margin-right: -20px;
  transition: 0.3s;

  @media (prefers-reduced-motion: reduce) {
    transition: none;
  }

  button{
    width: 40px;
    height: 40px;
    /*border-radius: 50%;
    background: #6749b9 !important;
    border-color: #6749b9 !important;*/
  }
}
nav {
  padding-inline-start: 5px;
  color: #555;
  transition: all 0.3s;
  position: fixed;
  height: 90%;
  overflow-y: auto;

  @media (max-width: 991px) {
    position: relative;
  }

  ul {
    padding: 0;
    padding-right: 10px;
    background-color: var(--bg);

    li{
      div {
        display: flex;
        align-items: center;
        cursor: pointer;
        font-size: 16px;
        padding: 10px 0;
        /*color: rgba(255, 255, 255, 0.8);*/
        color: #555;
        /*border-bottom: 1px solid var(--light-grey);*/
        padding-inline: 15px;
        margin-block: 2px;
        &:hover {
          background: var(--light-green);
        }

        app-icon{
          width:30px;
        }
      }
    }
  }
  .options {
    width: 225px;

    @media (max-width: 991px) {
      width:100%;
      font-size: 22px;
    }

    li{
      &.sidebar-item {
        @media (max-width: 991px) {
          background: white;
          margin-bottom: 5px;
          border-radius: 4px;
          padding: 4px;
          width: calc(100svw - 35px);
        }
      }
    }
  }
}
.sections {
  width: 100%;
  padding: 4px;
  margin-inline-start: 250px;
  min-height: 60svh;

  @media (max-width: 991px) {
    margin-inline-start: 0;
  }
}
.f-hide {
  width: 0;
  height: 0;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;

  &+label {
    background: var(--green);
  /*  padding: 9px 18px;*/
    color: #fff;
    border-radius: 4px;

    &+span{
      max-width: 200px;
      overflow: hidden;
      display: inline-block;
    }
  }
}
.file-closer{
  position: absolute;
  margin-block-start: -10px;
  margin-inline-start: -10px;
}
.file-input-img {
  max-width: 200px;
}
.site-menu {
  position: fixed;
  top: 0;
  width: 100%;
  background: var(--bg);
  z-index: 902;
  /*padding-inline-end: 3px;*/
  /*padding-inline-end: calc(3px + 1%);*/
  height: 86px;

  @media (max-width: 991px) {
    z-index: 10000;
    height:inherit;
  }

  .cont{
    display:flex;
    /*padding: 18px 10px;*/
    padding-block: 18px;
    align-items: center;
    justify-content: space-between;
    /*margin-inline-start: 14px;*/
    padding-inline-end: 3px;
    @media (min-width: 1400px) {
      margin-inline: 10px;
    }
    @media (max-width: 991px) {
      height:50px;
    }

    & > div{
      &:first-child{
        /* width:calc(24vw + 30px); */
      }
    }
    i:focus {
      text-shadow: -1px 4px 9px var(--green);
    }

    .main-menu{
      /*background-image: url(assets/images/action-menu-bg.png);*/
      background: linear-gradient(45deg, #ffdd22,#ff37c8);
      background-repeat: no-repeat;
      background-position: center center;
      display:flex;
      border-radius:18px;
      height: 35px;
      box-shadow: inset 0 -1px #3534342b,
      0 12px 36px #35343426 !important;
      margin-inline-start: 7px;


      @media (min-width: 992px) {
        padding-inline: 11px;
      }
        @media (max-width: 991px) {
        border-radius: 0 0 10px 10px;
        border: 1px solid var(--bg);
        max-width: 100%;
        width: 100%;
        height: 65px;
        position: fixed;
        bottom: 0;
        box-shadow: 0 0 4px var(--orange);
        justify-content: space-around;
        margin-inline-start: inherit;
      }

      img{
        @media (max-width: 991px) {
          padding-block: 10px;
        }
      }

      & > .active{
      /*background:orange;*/
        backdrop-filter: brightness(0.8);
        border-radius: 4px;
      }
      & > div{
        /*padding: 6px 20px;*/
        padding: 6px 5px;
        margin: 0 15px;
        max-width: 66px;

        @media (min-width: 992px) {
          max-width: 35px;
        }
        @media (max-width: 991px) {
          /* padding-inline: 10px;*/
          padding: 0 10px;
          margin: 6px 5px;
        }

        &:last-child{

          @media (max-width: 991px) {
            padding-block: 10px;
          }



          & > div{
            width: 20px;
            height: 20px;
            text-align: center;
            background-color: #fff;
            border-radius: 50%;
            margin-top: 2px;
            font-weight: bold;
            color: var(--red);
            cursor: pointer;

            @media (max-width: 991px) {
              margin-top: 0;
              width: 30px;
              height: 30px;
              font-size: 22px;
              box-shadow: inset 0 -1px #3534342b;
            }
          }
        }



      }
      app-icon{
        &:hover {
          filter: drop-shadow(0 0 1px #00000080);
        }
      }

    }


    .user-menu{
      display: flex;
      align-items: center;
      /* margin-inline-start: auto; */

      & > div{
        padding-inline-start: 40px;

        @media (max-width: 991px) {
          padding-inline-start: 10px;
        }
      }
    }
    .menu-float{
      position: absolute;
      /*background-color: white;*/
      display: flex;
      flex-direction: column;
      margin-block-start: 6px;
      margin-inline-start: -12px;
      padding-block:4px;
      border: 0;
      background: #ffffff;
      border-radius: 4px;
      border-top-right-radius: 0;
      box-shadow: 1px 1px 3px 0px var(--orange);

      @media (max-width: 991px) {
        position: fixed;
        bottom: 65px;
        /*margin-inline-start: -72px;*/
       /* box-shadow: -1px -1px 3px 0px var(--orange);*/
        right: 0px;
        width: 100svw;
        margin: 0;
        border-radius: 10px 10px 0 0;
        gap: 11px;
        font-size: 16px;
        box-shadow: 0px -10px 20px 20px #0000003d;
        overflow-y: scroll;
        height: fit-content;
        max-height: calc(100svh - 120px);
      }

      /* &.menu-float-home{
         @media (max-width: 991px) {
           margin-inline-start: -16px;
        }
      }*/

      & > div{
        padding: 3px 10px;
        cursor: pointer;
        display: flex;
        align-items: center;

        &:hover{
          background-color: #f5f5f5;
        }
        & > div{
          &:first-child{
            width:20px;
            display: flex;
            justify-content: center;
            margin-inline-end: 6px;
            align-items: center;
          }
        }
      }
    }
  }
}
.open-patient-form img{
  padding-inline: 5px;
  &:hover{
    filter: brightness(0.85);
  }
}

.button-group{
	display: flex;
	flex-wrap: wrap;
	min-width:150px;
  /* ggdf */

  &.duo{
    & button {
        margin-inline: 0 !important;
        border-radius: 0;

      &:first-child{
        border-start-start-radius: 10px;
        border-end-start-radius: 10px;
      }
      &:last-child{
        border-start-end-radius: 10px;
        border-end-end-radius: 10px;
      }
    }
  }
  &.tab{
    border-block-end: 1px solid #ced4da;

    & button{
      border-radius: 10px;
      border-end-end-radius: 0;
      border-end-start-radius: 0;
      border-bottom: 0;
    }

  }
}
.hidden-calendar {
  position: absolute;
  top: -40000000px;
  z-index: -500000;
}


.home-container{
  @media (max-width: 991px){
    overflow-x: hidden;
    width: 100%;
  }

  & > div{
    &:first-child{
      width: 24%;
      width: max(300px, calc(var(--cal-width)));

      @media (max-width: 991px){
        width:auto;
      }
    }
  }
  .user-home-meeting-wrapper{
    margin-top: 10px;

  }
}
.details-list{
  font-size: 16px;
  margin-inline-end: 2px;
  line-height: 40px;
}
.details-text{
  padding: 1px;

  p{
    font-size: 16px;
    margin-inline-end: 20px;
  }
}
.home-button{
  width:50%;
  justify-content: end;
  height:40px;
  font-size: 20px;
  font-weight: bold;

  @media (max-width: 991px) {
    width:100%;
    height:40px;
  }
}
.upper-row{
  display: flex;
  justify-content: left;
  align-items: center;
  height:60px;
  padding:0 40px;
  position: fixed;
  z-index: 100;
  background-color: var(--bg);
  /* background-color: #FFF962;*/
  width:100%;

  img {
    width: 200px;

  }
}

.user-details{
  margin-inline-end: auto;
  margin-inline-start: 40px;
  background-color: white;
  padding: 10px;
  height: fit-content;
  border-radius: 4px;
}


.guest-sched-container{
  display: flex;
  justify-content: center;
  padding-top:5px;
  width: 992px;
  margin: auto;

  @media (max-width: 991px) {
    width: inherit;
    margin: inherit;
  }

  & > div{
    width: 70%;

    @media (max-width: 991px) {
      width: 100%;
    }
  }
}

.scheduler{
  margin: 0 15px 30px;
  padding-block: 1.5rem;
  font-size: 20px;
  border-radius: 4px;
  box-shadow: 0 12px 36px #3534340f;
  background: white;
  height: fit-content;

  & > div{
    &:first-child{
      padding: 2px 0;
      color: var(--green) !important;
      font-weight: 700;
      font-size: 28px;

    /*  p {
        color: var(--black);
        font-weight: 500;
        font-size: 18px;
        padding-top: 20px;
      }*/
    }

  }
}

.product{
  margin: 0 15px 30px;
  border-radius: 4px;
  box-shadow: 0 12px 36px #3534340f;
  background: white;
  height: fit-content;
  width: 96%;

}

.followups-loader {
  padding: 10px;
  display: flex;
  align-items: center;

  span {
    margin: auto 10px;
  }
}
.invoice-line-tr {
  border-bottom: 1px #e3e3e3 solid;
  margin-bottom: 10px;
}
.header-totals-row{
  div{
    background-color: #F9F9F9;
  }
  span{
    direction: ltr;
    display: inline-block;
  }

}
.id-number{
  display: flex;

  & > div{
    margin-inline-start: 10px;
    display: flex;
    align-items: center;
  }
}
app-autocomplete-multi{
  .tags-container {
    margin: 2px 0;
  }
}

.ac-common-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .tags-container {
    /*border: 1px#cccccc solid;*/
    padding: 0;
    color: #555;
    border-radius: 4px;
   /* background-color: white;*/
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    /* min-height: 32px; */
    position: relative;

    @media (max-width: 991px) {
      min-height: 28px;
    }

    .ac-tag {
      margin: 2px 6px;
      padding: 1px 8px;
      background-color: var(--green);
      color: white;
      border-radius: 4px;
      display: flex;
      align-items: center;
    }

    .input-cont {
      /* position: relative; */
      display: flex;
      flex-grow: 1;


      input {
        border: 0;
        /*height: 100%;*/
        height: 30px;
        border-radius: 4px;
        font-size: 14px;
        min-width: 100px;
        box-shadow: inset 0 -1px #3534342b;
        flex-grow: 1;
      }

      &>div {
        width: 100%;
        position: absolute;
        background-color: white;
        z-index: 100;
        max-height: 200px;
        overflow-y: auto;
        margin-inline-start: -4px;

        &>div {
          padding: 4px;
          border: 1px #ccc solid;
          cursor: pointer;
        }
      }

    }
  }
  .plus {
    height: inherit;
  }
}

.btn-back{
  @media (max-width: 991px) {
    width:100px;
    font-size: 16px;
  }
}
.green-stripe{
  color: white;
  background-color: var(--green);
  padding:10px;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 20px;
  display: inline-block;
  padding: 6px 12px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 4px;
  margin-inline-start: 5px;
  width: 90%;

  p{
    width:fit-content;
    font-size: 16px;

    @media (max-width: 991px) {
      font-size: 16px;
      margin-inline-start: 0;
    }
  }
}
.is-new-person {
  background-color: var(--light-green);
  margin-inline: 6px;
  padding-inline: 10px;
}
.is-new-person > div > div{
  margin-bottom: 10px;
}
.patient_contact_meeting_row {
  padding-block: 10px;
}
.save-on-vac {
  background-color: orange;
}

.change-series-wrapper{
  background: #e8f2dc;
  padding: 15px 5%;
  border-radius: 4px;
  margin-inline: 20px;
  box-shadow: inset 0 -1px #3534342b;
  margin-block: 30px;
}
.change-series-wrapper22222{
  /*padding-inline-start: 5%;*/
  /*background-color: #d8f5b0;*/
  padding-top:20px;
  /*border-radius: 6px;*/
  border-radius: 10px;
  border: 3px solid var(--red);
  width: 93%;
  margin: auto;
}
.change-series-title {
  margin-inline: 20px;
  background: var(--red);
  color: white;
  font-weight: 900;
  border-radius: 4px 4px 0 0;
  padding: 5px;
  cursor: default;
  font-size: 14px;
  margin-bottom: -4px;
  z-index: 1;
  position: relative;
  margin-top: 20px;
}
.date-row{
  button{
    margin-inline-end: 10px !important;

    .btn {
      font-size: 14px;
      font-weight: bold;
    }
  }
  & > * {
    &:first-child {
      width: 70px;
    }
    &:nth-child(2) {
      width: 70px;
    }


    &:last-child {
      width: clamp(185px, 100%, 300px);
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 20px;
    }
  }
  h6{
    font-size: 16px;
    margin-inline-end: 20px;
    margin-block: 0;
  }
}
.dates-title{
  font-size: 22px;
  color: var(--green);
}
.dates-row{
  font-weight: bold;
  display: flex;
  justify-content: center;

  .cal-btn{
    height: 30px;
  }
}
.hours-space{
  min-height: 240px;

  @media (max-width: 991px) {
    min-height: 1px;
  }
}

.chunks{
  display:flex;
  flex-flow: wrap;

  & > div{
    margin: 4px 10px;
    display: flex;

    & > div{
      padding:3px 8px;
      /* border-radius: 3px; */
      color:white;
      height:100%;
      cursor: pointer;

      &:first-child {
        background-color: var(--green);
        border-start-start-radius: 3px;
        border-end-start-radius: 3px;
      }
      &:last-child{
        border-start-end-radius: 3px;
        border-end-end-radius: 3px;
      }
      &:nth-child(2){
        background-color: darkgrey;
      }
    }
  }
}
.meeting-times{
  .date-row{
    button{
      padding: 0;
      margin: 10px;
    }
    td{
      &[valign=top]{
        padding-top: 9px !important;
      }
    }
  }
}
.guest-hours-sched{
  .btn-primary{
    &.active,&:active {
      background-color: var(--green) !important;
      border-color: var(--green) !important;
    }

    /*background-color: #FFF962 !important;*/
    /*color: #0c0c0c !important;*/
    border: none !important;
    margin-bottom: 2px;
  }

  .dates-row{
    justify-content: space-between;
  }
}



.testquestions-tbl{
  /*background-color: white;*/
  padding:10px 0;
  display: grid;
  gap: 10px;
  width: 100%;
  border-bottom: 1px solid var(--light-green);

  hr{
    &.new-row{
      height:2px;
      background-color: var(--dark-green);
    }
  }

  tr{
    &.new-row {
      background: #e8fffe !important;
    }

    td{
      padding-block: 4px !important;
    }
  }


  & > div{
    /* display: flex;
    flex-wrap: wrap; */

    &:first-child{
      /* border-bottom: 1px var(--grey) solid; */

      /* @media (max-width: 991px) {
        display:none;
      } */

      & > div{
        font-weight: bold;
      }

    }

    &.new-row{
      background: #e8fffe;
    }

    > div{
      /* width:200px; */
      padding-block: 4px;
      /* padding-inline: 4px; */
      width: 20px;

      & > label{
        display: none;

        @media (max-width: 991px) {
          display:block;
        }
      }

      /* &:first-child{
        width:500px;
      } */
    }
    &.new-row-scales{
      background: #e8fffe;
    }

    > div{
      /* width:200px; */
      padding-block: 4px;
      /* padding-inline: 4px; */

      & > label{
        display: block;

        @media (max-width: 991px) {
          display:block;
        }
      }

      /* &:first-child{
        width:500px;
      } */
    }
  }

  .test-options, .interpretation, .avg_sd, .remarks {
    @media (min-width: 992px) {
      padding-inline-start: 20px;
    }

    .title {
       color:var(--green);
       font-size:16px;
       font-weight: 600;
       padding-top: 10px;

    }
    
  }
}

.question {
  width: 100%;
}

.question-number {
  font-weight: 600;
  font-size: 16px;
  color: var(--green);
}

.question-container {
  padding: 12px;
  &:hover {
    border-radius: 4px;
    background-color: var(--light-grey);
    transition: .1s;
  }
}

.answer-suboptions {

}

.popup {
  z-index: 99999999;
  position: absolute;
  background-color: white;
  user-select: none;

  .noInput &{
    background-color: inherit;
    border: 0;
    z-index:inherit;
  }

  .cal-container{
    display: flex;

    .noInput &{
      width: 180px;
      min-height: 160px;
      font-size: 16px;
      border: 1px solid var(--green);
      border-radius: 10px;
    }

    .cal-col {
      width: 50%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;
    }

    .vals {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }

    .cal-col,.vals{
      div{
        width: 48px;
        height: 48px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }
    }

    .cal-row.main div,.cell {
      width: 14%;
      height: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 4px;
    }
    .cal-row{
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;

      .noInput &{
        &.header  {
          height: 28px;

          div {
            height: 28px;
          }
        }
        &.main{
          div{
            height: 28px;

            &.act {
              background-color: var(--green);
            }
          }
        }
      }

      .cell {
        cursor: pointer;
        height: 28px;
      }

      &.header {
        justify-content: space-between;
        cursor: pointer;
        background: var(--green);
        color: white;

        div {
          height: 24px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      &.main{
        &.qrt{
          div {
            width: 25%
          }
        }
        div{
          &.today {
            /* border: 1px black solid; */
          }
          &.act {
            color: white;
            background-color: var(--green);
          }
          &.disabled {
            color: grey;
            cursor: not-allowed;
          }
        }
      }
    }
  }
}
.date-picker{
  max-width:130px;

  .popup {
    /* width: 180px; */
    /*border: 1px solid #cccc;*/
    /*border-radius: 12px 0 12px 12px*/
    margin-block-start: 3px;
    margin-inline-start: 1px;
    border: 0;
    border-radius: 4px;
    border-top-right-radius: 0;

    .cal-container {
      flex-direction: column;
      box-shadow: 1px 1px 3px 0 #3e6c05;

    }
  }

  button{
    display: flex;
    /*padding: 7px;*/
    border-radius: 4px;
    border: 0px;
    background-color: var(--green);
    justify-content: space-around;

    @media (max-width: 991px){
      /*padding:3px;*/
       padding-inline: 10px;
    }
  }
}
.time-picker{
  width:100px;

  .popup {
    direction: ltr;
    /* width: 280px;
    height:100%; */
    /* border: 1px black solid;*/
    border-radius: 0 0 4px 4px;
    box-shadow: inset 0 -1px #3534342b;
    height: 120px;

    .cal-container {
      height: 100%;
      flex-direction: row;
    }
  }
}
.tasks-table{
  margin-bottom: 20px;
  color: var(--black);
  cursor: default;

        .comments-container {
      /* margin: 0 40px; */
      display: flex;
      flex-direction: column;
      /* align-items: flex-end; */
      gap: 10px;
      background-color: white;
      padding: 20px;

      @media(max-width: 575px) {
        /* margin-inline: 0; */
      }


        .userName {
          opacity: 0.9;
          font-size: 12px;
        }

      }

  .comment {
    padding: 6px 8px;
    border-radius: 10px 0 10px 10px;
    max-width: fit-content;

    &.mine {
      align-self: flex-start;
    }
  }

  .task {
    display: grid;
    grid-template-columns: 15px calc(100% - 15px);
    border-bottom: 1px solid var(--green);
    margin-block: 10px;
    margin-inline: 10px 0;
  }

  .task-sidebar {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding-top: 15px;
  }

  .task-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .task-box {
    /* background-color: var(--light-green); */
    border-radius: 4px;
    padding: 0 12px;
    /* box-shadow: -27px 17px 36px -21px #00000021; */
    max-height: 70svh;
    overflow-y: auto;
  }

  .task-details {
    background: #d3e1c9;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 6px;
    padding-block: 12px;
    padding-inline: 20px;
    position: sticky;
    top: 0;
    z-index: 2;

    & .task-title {
      display: flex;
      justify-content: flex-start;
      gap: 4px;
      background-color: var(--green);
      color: white;
      font-weight: 600;
      padding-inline: 4px;
      cursor: pointer;

      &:hover {
        background: var(--dark-green);
      }

    }

    .task-sender {
      color: var(--black);
      border: 1px solid var(--green);
      border-radius: 4px;
      padding: 0 6px;
      display: flex;
      align-items: center;

    }

    & > div:last-child {
      display: flex;
      justify-content: flex-end;
      gap: 4px;


      & > div {
        background-color: var(--green);
      color: white;
      font-weight: 600;
      padding-inline: 4px;
      letter-spacing: .25px;
      }

    }

    .patient {
      cursor:pointer;

      &:hover {
        background: var(--dark-green);
      }
    }

  }

  .task-actions {
    padding: 0 12px;
  }

  .new-comment {
    /* margin-inline-start: 40px; */
    position: sticky;
    bottom: 0;
    /* padding-block: 4px 12px; */
    background-color: #eff5eb;
    border-radius: 0 0 4px 4px;
    padding: 20px;

    @media(max-width: 575px) {
      margin-inline: 0;
    }
  }

  .comment-motion-content {
    cursor: pointer;
  }

  .ellipsis{
    font-weight: bold;
    /* align-self: flex-end; */
    cursor: pointer;
  }

  .recipient-list {
    display: flex;
    align-items: center;
    margin-inline-start: 40px;
    min-height: 30px;

    @media(max-width: 575px) {
      margin-inline: 0;
    }
  }

  .recipient {
    display: flex;
    align-items: center;
    padding: 0 6px;
    color:var(--black);
    border-radius: 4px;
    border: 1px solid var(--green);

    &.uptodate {
      background-color: var(--green);
      color: white;
    }
  }
}
.gen-autocomplete-wrapper{

  ul {
    position: absolute;
    /* width: 170px;   */
    z-index: 9999999999999999;
    /*border-bottom: 0;*/

    border: 1px solid #ccc;
    border-top: 0;
    border-radius: 0 0 4px 4px;
    background: white;
    overflow-y: auto;
    box-shadow: 0px 15px 27px -5px #1e1d1a47;
    max-height: 450px;

    li {
      /* width:100px; */
      /*background: #F3FAFE;*/
      /*color:#a61c00;*/
      /* border-bottom: 1px solid #ccc;*/
      /* height: 30px; */
      font-size: 14px !important;
      padding: 5px 10px;
      cursor: pointer;
      overflow: hidden;

      &:hover{
        background: var(--orange);
        color: var(--black);
      }
    }
  }

  input {
    padding: 5px 7px;
    /*border: 1px solid #ccc;*/
    border: 0;
    box-shadow: inset 0 -1px 0 #3534342b;
    /* border-radius: 4px; */
    width: 100%;
    /* border-radius: 4px 0 0 4px; */
    border-inline-end: 0;
    border-radius: 0 4px 4px 0 !important;
    margin-block: 2px;
  }

  .searchClear {
    position: absolute;
    margin-inline:-18px 0;
    /* margin-top: 10px; */
    top: 18px;
  }
  /*::-webkit-scrollbar-thumb {
    background:
     var(--green) !important;
  }*/
}

.searchBar {
  @media (max-width: 576px) {
    width: 100%;
    cursor: pointer;


    .input-group {
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }

    input,.searchClear{
      opacity:0;
    }

    .input-group-addon {
      box-shadow: none;
      background: transparent;
      scale: 1.5;
      position: absolute;
      right: 0;
    }

    &:focus-within {
      position: absolute;
      width: 100svw;
      right: 0;
      top: 0;
      z-index: 2;
      background: white;
      box-shadow: inset 0 -1px 0 #3534342b;

      input,
      span {
        opacity:inherit;
        height: 50px;
        border-radius: 0 !important;
        margin-bottom: 0;
        position: static;
      }
      .searchClear{
        opacity: inherit;
      }

      .input-group-addon {
        padding-inline-end: 10px;
      }

    }

    ul,.items-container {
      width: 100svw !important;
    }
    .gen-ac-opt {
      padding-block: 10px !important;
    }

    .searchClear {
      top: 27px;
      margin-inline: -25px 0;

    }
  }

}

.price-payor-patient{
  max-width: 90px;

}
.followup-title {
  margin: 0;
  margin-top: 30px;
  font-size: 32px;
  font-weight: bold;
  text-align: start;
  color: var(--green);
  cursor: default;
  padding: 4px;
}
.payorprices-btn{
  margin-top:21px;
  height:30px;

  @media (max-width: 991px) {
    display: flex;
    align-items: center;
    margin-top: 23px;

  }
}
.texts-btn{
    box-shadow: none;
    background: transparent !important;
    color: #838383 !important;
    text-shadow: none !important;
    font-size: 14px !important;
  margin-top:15px;
  height:30px;

  @media (max-width: 991px) {
    margin-top: 0;
    margin-inline-start: 5px;
    padding-inline: 10px;
    padding-block: 4px;
  }



  img {
      filter: brightness(0) saturate(100%) invert(51%) sepia(10%) saturate(8%) hue-rotate(354deg) brightness(99%) contrast(97%);
      height: 18px !important;
    }

  span {
    margin-inline-start: 8px;

    @media (max-width: 1605px) {
      display: none;

    }
  }
}

.btn.texts-btn:hover{
  &:hover{

      color: #333 !important;
      background-color: #e6e6e6 !important;
      border-color: #adadad !important;


  }
}
.updated-saving{
  margin-block: 5px;

  @media (max-width: 576px) {
    width:100%;
    margin-block:inherit;
  }
}
.next-previous{
  display: flex;
  gap: 6px;
  @media (max-width: 768px) {
    margin-top: 15px;
    order: 6;
    display: flex;
    justify-content: space-around;
    width: 100%;
  }
}
.home-meeting-wrapper{
  /* padding-inline-end: 0; */
  @media (min-width: 576px) {
    padding: 20px;
  }
  @media (min-width: 992px) {
    margin-inline-start: 20px;
  }

  label{
    font-size: 10px;
    color: var(--black);
  }
  .form-group {
    margin: 0;
    padding: 0;
    margin-bottom: 7px !important;

    @media (max-width: 991px) {
      &:nth-of-type(1) {
        padding-block-start: 10px;
      }
    }
  }
  .images-container {
    display: flex;

    &>div {
      margin: 10px;
      display: flex;

      div {
        margin-top: -4px;
        cursor: pointer;
      }

      img {
        max-width: 150px;
        max-height: 100px;
        cursor: pointer;
      }
    }

  }
}
.home-meeting-upper-details{
  @media (min-width: 992px) {
    /* margin-bottom: 25px; */
    margin-block: -15px 0;
  }
}


.gen-table-wrapper{

  .fuzzy-wrap {
    display: flex;
    align-items: baseline;
  }

  .gen-tbl-header-top {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-block: 10px;
    direction:rtl !important;

    .clear-search{
      z-index:500;
      width:20px;
      margin-inline-start: -46px;
      margin-inline-end: 26px;
      margin-block-start: 2px;
    }

    .num-badge{
      margin: 4px;
      display: inline-block;
      padding: 3px 7px;
      font-size: 12px;
      color: #fff;
      vertical-align: middle;
      background-color: #777;
      border-radius: 10px;
    }
    .vis-flip-div {
      position: absolute;
      z-index: 300;
      padding: 12px;
      background-color: #fffe;
      border: 1px #ccc solid;
      border-radius: 10px;

      input {
        margin: 0 10px;
      }
    }

    .add-btn {
      height: fit-content;
      align-self: flex-end;
      margin-inline-end: 0;
      margin-inline-start: auto;
    }

    > div{
      margin-bottom:4px;
    }
    & h4{
      margin-block:0;
    }
    .filter-row {
      /*	margin-top:10px;*/
      display: flex;
      margin-inline-start: auto;
      flex-wrap: wrap;

      > div{
        margin-inline: 10px;
        margin-bottom:4px;
      }
    }
  }




  table {
    /*background-color: white;*/
    background: transparent;

    app-gen-autocomplete {
      width: 100%;
    }




    .new-row-extra {
      height: 20px;
    }
  }
}

.new-row {
  background: var(--light-green) !important;
  width: 100%;
  padding: 12px 10px;
  border-radius: 4px;

  .add-btn {
    width: 120px;
    display: flex;
    align-items: center;
    width: fit-content !important;
  }
}

.tooltipevent {
  width: 250px;
  padding: 5px 10px;
  border: 1px solid grey;
  border-radius: 5px;
  background: #fffff7;
  position: absolute;
  z-index: 9999999999999999999999;

  .flex-sp-bet{
    display: flex;
    justify-content: space-between;
  }
}
.calendar-above-row{
  @media (min-width: 992px){
    width: calc(100% - 3px);
  }
  .cal-icon{
    font-size: 24px;
    min-width: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    /*height: 28px;*/
    /* margin-inline: 5px; */
    /*height: 35px;*/
    height: 30px;

  }

  .end-or-center{
    /*width:300px;*/
    display: flex;
    justify-content: flex-end;

    @media (max-width: 991px){
      height: 30px;
      padding-inline-start: 20px;
    }
  }

  .cal-btns-mobile {
    width:fit-content;

    @media (max-width: 991px){

      position: fixed;
      width: 100% !important;
      background: linear-gradient(135deg,#ffe11e,#ff44bb);
      z-index: 900;
      bottom: 64px;
      right: 0;
      border-radius: 0;
      height: 40px;
      display: flex;
      align-items: flex-end;
      border: 1px solid var(--bg);
      border-radius: 10px 10px 0 0;

      .is-guest &{
        bottom:0;
      }

      & .cal-btn {
        border: 0;
        width: 28.5%;
        font-size: 16px;
        padding: 2px 0;
        cursor: pointer;
        color: #fff;
        font-weight: 700;
        letter-spacing: 1px;
        border-bottom: transparent 6px solid;
        margin-inline: 3px;
        background: transparent;
        border-radius: 0;
        box-shadow: none !important;

        &.active{
          border-bottom: white 6px solid !important;
          background: transparent !important;
        }
      }
    }
  }


  &.home-vers{
    position: sticky;
    width: auto;
    justify-content: space-between !important;
    align-items: flex-start;
    /*padding-bottom: 10px;*/
    top: 86px;
   /* height: 55px;*/
   /* padding-inline: 4px;*/

    /* .today-btn{
      @media (1200px > width > 991px){
        display: none;
      }
    } */

    @media (max-width: 991px){
      position: fixed;
      width: 100%;
      padding-inline: 3px;
      align-items: end;
      /*padding-top: 10px;*/
      /*top: 50px;*/
      /*height: 45px;*/
    }

    & > div{
      display: flex;
      gap: 5px;
      @media (max-width: 991px){
        align-items: flex-end;

      }
    }
  }
}

.login-register{
  /* background-image: url('../images/login-bg.jpg'); */
  background-image: url('assets/images/login-bg.jpg');
  background-size: cover;
  background-attachment: fixed;
  min-height: 100vh;

  @media (max-width: 768px) {
    background-position: right -424px top;
  }

  .login-page {
    height: 100%;
    /*min-height: 920px;*/
    text-align: start;
    padding-top: 90px;

    @media (max-width: 991px) {
      padding-top: 30px;
    }


    .login-form {
      width: 100%;

      .form-right {
        /*padding-block: 40px;*/
        /*padding-inline: 65px;*/
        padding-inline: 150px 20px;
        max-width: 495px;
        border-radius: 4px;
        /*box-shadow: 0 0 180px 0 rgba(159, 253, 50, 0.55);*/
        /*background-color: var(--bg);*/
        margin-bottom: 20px;

        @media (max-width: 1200px) {
          padding-inline: 100px 20px;
        }
        @media (max-width: 991px) {
          padding-top: 20px;
          margin: 0 auto;
          padding-inline: 30px;
        }

        .login-header {
          text-align: start;
          /*padding-left: 100px;*/

          @media (max-width: 991px) {
            /*text-align: center !important;*/
            padding: 0 !important;
            display: block !important;
          }

          p {
            margin-top: 15px;
            color: #ffffff;
            font-size: 27px;
            line-height: 1;
            letter-spacing: -0.32px;
          }
          img {
            max-width: 288px;
          }
        }

        .form{

          button{
            &:hover {
              color: white;
              background: var(--green);
            }
          }

          &.greeting {
            background: white;
            padding: 20px 20px 10px 20px;
            border-radius: 0 20px 20px 0;
            border: 2px solid var(--orange);
          }

          .error {
            color: white !important;
           /* background: #FA633C;*/
            font-size: 16px;
            padding: 10px;
            border-radius: 0px 0px 10px 10px;
          }

          .text-agree-cont {
            background: white;
            margin-inline-start: 1px;
            margin-inline-end: 1px;
            margin-bottom:0 !important;
            padding: 10px;
            padding-top: 2px;

            .checkbox-wrapper{
              margin-block: 8px;

              @media (max-width: 991px) {
                margin-block: 0;
              }

              .agree-to-terms{
                margin: 5px 10px;
              }
            }
          }

          .login-text-button {
            font-size: 18px;
            letter-spacing: -0.44px;
            color: #2f2f2f;
            background: var(--bg);
            margin: 10px 0;
            border: 0;
            border-radius: 4px;
            width: 173px;
            font-weight: bold;
            padding: 3px 12px;
            text-align: start;
            box-shadow: inset 0 -1px #3534342b;

          }
          .success-msg {
            background: var(--orange);
            color: white;
            width: fit-content;
            border-radius: 4px;
            padding: 1px 4px 1px 4px;
          }




          .form-group {
            margin-bottom: 20px;
            padding-inline: 0;

            .text-info{
              color: white !important;
              background: var(--red);
              padding: 10px 10px 10px;
              font-size: 16px;
            }

            &.showPassword{
              position: relative;

              & > div{
                &:first-child{

                  position: absolute;
                  /* width: 100%; */
                  margin-inline-start: calc( 100% - 43px );
                  height: 100%;
                  display: flex;
                  justify-content: end;
                  padding-inline: 10px;

                  /*align-items: center;*/
                  align-items: flex-start;
                  top: 12px;
                }

              }
            }
          }

        }
      }


      .form-left{
        width: 450px;

        .form-left-top {
          overflow: hidden;
          width: 100%;
          padding: 30px;
          border-radius: 4px;
          box-shadow: 0 12px 36px #35343426;

          background-color: var(--bg);

          .h2-smaller-font{
            font-size:34px;
          }

          h2 {
            color: var(--green);
            font-size: 52px;
            font-weight: bold;
            line-height: 1;
            letter-spacing: -1.25px;
            margin-bottom: 10px;

            @media (max-width: 991px) {
              font-size: 32px;
            }

            strong{
              color:#777;
            }
          }
          .affiliate-tbl{
            div{
              span{
                &:last-child{
                  line-height: 30px;
                  display: flex;
                  align-items: center;
                }
              }
            }
            app-icon{
              padding-inline-end: 5px;
            }
          }
        }
        .form-left-bottom {
          overflow: hidden;
          padding-top: 45px;
          margin-inline-start: -2px;

          p {
            text-align: start;
            color: #ffffff;
            font-size: 25px;
            line-height: 28px;
            letter-spacing: -0.5px;
            margin: 8px;
          }
        }

        @media (max-width: 991px) {
          padding-top: 20px;
          margin: 0 auto;
          width: 100%;
        }
      }


    }
  }
}
.gen-table-wrapper, #section-to-print-mixed-list{
  tbody{
    &:hover{
      & tr{
        background-color:  var(--light-green);
      }
    }
  }
}
.center{
  display: grid;
  place-items: center;
}

/*  {
  transition: .2s;
}*/

/*#home-side-patient-col {*/

/*  position: fixed;*/
/*  width: 27%;*/
/*  height: calc(100% - 165px);*/
/*  overflow-y: auto;*/
/*}*/

/*#home-side-patient-col:dir(rtl) {*/
/*  left: 11px;*/
/*}*/

/*#home-side-patient-col:dir(ltr) {*/
/*  right: 11px;*/
/*}*/
/* TODO comment out before production*/
@media  (max-width:991px) and  (orientation:landscape) {
  .site-menu .cont .main-menu {
    top: 0;
    height: 100svh;
    width: 65px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 0 10px 10px 0;
  }

  .site-menu .cont .main-menu img {
    height: 55px !important;
  }


  .mobile-tabs {
    bottom: 0;
    background: linear-gradient(315deg, #ffe11e, #ff44bb);
  }


  .calendar-above-row .cal-btns-mobile {
    bottom: 0;
    background: linear-gradient(315deg, #ffe11e, #ff44bb);
  }


  .help-btn {
    bottom: 5px;
  }


  .site-menu .cont .menu-float {
    right: 65px;
    width: calc(100svw - 65px);
    max-height: calc(100svh - 55px - 40px) !important;
    top: 55px;
    border-radius: 0 4px 4px 0;
    display: grid;
    grid-template-columns: 1fr 1fr;


  }


  .modal-content {
    right: 65px;
    width: calc(100svw - 65px);
  }


  #home-side-patient-col {
    margin-bottom: 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 15px;
  }


  .router-cont {
    min-height: 100px;
    margin-inline-start: 65px;
  }


  .person-content {
    padding-bottom: 0;
  }


  .center-area {
    margin-bottom: 0;
  }


  .mobile-tabs li {
    width: 28%;
    margin-inline: 0;
  }


  .calendar-above-row .cal-btns-mobile {
    margin-right: 65px;
  }

  .calendar-above-row .cal-btns-mobile .cal-btn {
    width: 28%;
    margin-inline: 0;
  }
}

.login-affilite-text{
  color: var(--black);
  background-color: var(--orange);
  padding: 10px;
  border-radius: 4px;
  box-shadow: 0 7px 11px -7px #00000047;
  margin-bottom: 15px;
}

.login-affiliate-hr{
  height: 2px;
  background: black;
}

.register-title {
  font-size: 36px !important;
}

.register-subtitle {
  font-size: 20px !important;
  font-weight: 600;
  padding-block: 10px;
}

.register-text {
  font-size: 16px !important;
}


.testscale-tbl{ /* entire table */
  & > div{  /* row */
    & > div{ /* cell */
      &:nth-of-type(0){ /* questions */
        width:100px;
      }&:nth-of-type(1){ /* questions */
        /*width:200px;*/
      }&:nth-of-type(2){ /* questions */
        width:100px;
      }
      &:nth-of-type(4){ /* questions */
        width:200px;
        word-wrap: break-word;
      }
      &:nth-of-type(5){ /* questions */
        width:400px;

      }
      &:nth-of-type(6){ /* questions */
        width:500px;
      }
      &.input-number{
        width: 80px;
      }
    }
  }
}

